import React, { useCallback, useEffect, useState } from 'react';
import { Box, TextField, Tab, Tabs, Paper, Button, Typography, Select, MenuItem, InputLabel, FormControl, Card, Divider, CardContent, Pagination, Menu, CircularProgress, Grid, Modal, CardHeader, IconButton, FormControlLabel, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@mui/material';
import CustomDatePicker from '../common/customdatepicker';
import apiService from '../../services/actions';
import cancelIcon from "../../assets/images/cancelIcon.svg"
import successIcon from "../../assets/images/successIcon.svg"
import { formatDateToMMDDYYYY } from '../common';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';

const Reports = () => {
    const [tabValue, setTabValue] = useState(0);
    const [fromDate, setFromDate] = useState(dayjs().subtract(7, 'day'));
    const [toDate, setToDate] = useState(dayjs());
    const [loading, setLoading] = useState(false);
    const [print, setPrint] = useState(false);
    const [referredBy, setReferredBy] = useState('');
    const [status, setStatus] = useState('1');
    const [data, setData] = useState([]);
    const [hospitalId, setHospitalId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [page, setPage] = useState(1);
    const [advancedSearchPage, setAdvancedSearchPage] = useState(1);
    const itemsPerPage = 6;
    const patientDetailsPerPage = 5;
    const [advancedFiltersModal, setAdvancedFiltersModal] = useState(false);
    const [printModal, setPrintModal] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [patientLoading, setPatientLoading] = useState(false);
    const [patientDetails, setPatientDetails] = useState([]);
    const [advancedFiters, setAdvancedFilters] = useState({
        parentId: '',
        patientFirstName: '',
        patientLastName: '',
        mobileNumber: '',
        email: '',
        dateOfBirth: null
    });
    const [sort, setSort] = useState({
        sortBy: 'orderId',
        sortOrder: 'asc',
    });
    const tableRef = React.createRef();

    const getDataForPage = useCallback((page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const dataForPage = data.slice(startIndex, endIndex);
        return dataForPage;
    }, [data, itemsPerPage]);

    const getSortedData = () => {
        return getDataForPage(page).sort((a, b) => {
            const aValue = a[sort.sortBy];
            const bValue = b[sort.sortBy];

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sort.sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                const compareResult = aValue?.localeCompare(bValue);
                return sort.sortOrder === 'asc' ? compareResult : -compareResult;
            }
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        getDataForPage(page);
    }, [page, getDataForPage]);

    const parseLocationData = (locationData) => {
        try {
            const data = JSON.parse(locationData);
            const { hospitalId, locationId } = data;
            setHospitalId(hospitalId);
            setLocationId(locationId);
        } catch (error) {
            console.error('Error parsing location data:', error);
        }
    };

    useEffect(() => {
        const locationData = localStorage.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            parseLocationData(locationData);
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setData([]);
        setPage(1)
        handleSearch();
    };
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleReferredByChange = (event) => {
        setReferredBy(event.target.value);
    };

    const handleSearch = async () => {
        setLoading(true);
        let hospitalIdlocalCopy = null;
        let locationIdlocalCopy = null;
        const locationData = localStorage?.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            try {
                const data = JSON.parse(locationData);
                const { hospitalId, locationId } = data;
                setHospitalId(hospitalId);
                setLocationId(locationId);
                hospitalIdlocalCopy = hospitalId;
                locationIdlocalCopy = locationId;
            } catch (error) {
                console.error('Error parsing location data:', error);
            }
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
        const payload = {
            procedureStatus: tabValue === 0 ? status : undefined,
            status: tabValue === 1 ? status : undefined,
            fromDate: fromDate ? formatDateToMMDDYYYY(fromDate) : undefined,
            toDate: toDate ? formatDateToMMDDYYYY(toDate) : undefined,
            referredBy: referredBy ? referredBy : undefined,
            hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
            locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
            patientId: selectedPatient ? selectedPatient?.code : undefined,
        };
        try {
            const ordersDetailsResponse = await apiService.getOrderDetails(payload);
            setLoading(false);
            if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                let res = ordersDetailsResponse.data
                res.forEach(element => {
                    element.patientName = getPatientName(element);
                    element.gst = getGST(element);
                    element.totalAmount = getTotalAmount(element);
                });
                setData(res)
            } else {
                console.error('API error:', ordersDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }

    };

    useEffect(() => {
        handleSearch();
    }, []);


    const handlePrint = (e) => {
        // setPrint(true);
        // handlePrint2();
        setPrintModal(true);
    };
    const handlePrint2 = (e) => {
        setTimeout(() => {
            e.preventDefault();
            window.print();
        }, 1000);
        setTimeout(() => {
            setPrint(false)
        }, 1000);

    };

    const getPatientName = (obj) => {
        if (obj) {
            return obj.patientLabTestsOrderDetailsList[0]?.patientName;
        }
    }

    const getTotalAmount = (obj) => {
        let total = 0.00;
        if (obj && obj.patientLabTestsOrderDetailsList) {
            obj.patientLabTestsOrderDetailsList.forEach(element => {
                total += element.amount;
            });
        }
        return total;
    }
    const getGST = (obj) => {
        let gst = 0.00;
        if (obj && obj.patientLabTestsOrderDetailsList) {
            obj.patientLabTestsOrderDetailsList.forEach(element => {
                gst += element.gst;
            });
        }
        return gst;
    }
    const handlePatientPageChange = (event, value) => {
        setAdvancedSearchPage(value);
    };

    const handleRowClick = (event, row) => {
        event.stopPropagation();
        setSelectedPatient((prevSelected) => {
            if (prevSelected === row) {
                return null;
            } else {
                return row;
            }
        });
    };

    const handleCloseAdancedFilterModal = () => {
        setAdvancedFiltersModal(false);
        setSelectedPatient(null);
    };

    const handleClosePrintModal = () => {
        setPrintModal(false);
    };

    const handleInputChange = (field, value) => {
        setAdvancedFilters({
            ...advancedFiters,
            [field]: value,
        });
    };

    const getPatientDataForPage = useCallback((advancedSearchPage) => {
        const startIndex = (advancedSearchPage - 1) * patientDetailsPerPage;
        const endIndex = startIndex + patientDetailsPerPage;
        const dataForPage = patientDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [patientDetailsPerPage, patientDetails])


    useEffect(() => {
        getPatientDataForPage(advancedSearchPage);
    }, [advancedSearchPage, getPatientDataForPage]);

    const handleAdvancedSearch = async (e) => {
        setPatientLoading(true)
        let payload = {
            firstName: advancedFiters?.firstName ? advancedFiters?.firstName : undefined,
            lastName: advancedFiters?.lastName ? advancedFiters.lastName : undefined,
            mobile: advancedFiters?.mobile ? advancedFiters.mobile : undefined,
            email: advancedFiters?.email ? advancedFiters.email : undefined,
            dob: advancedFiters?.dob ? advancedFiters?.dob : undefined,
           

        };
        if(e =='P'){
            payload.code= advancedFiters?.code ? advancedFiters.code : undefined
            payload.status=1

        }
        else{
            payload.parentCode=advancedFiters?.code ? advancedFiters.code : undefined
        }
        try {
            const patientDetailsResponse = await apiService.getPatientDetails(payload);
            setPatientLoading(false)
            if (patientDetailsResponse.status === 200 && patientDetailsResponse?.data) {
                setPatientDetails(patientDetailsResponse.data)
            } else {
                console.error('API error:', patientDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };

    const onCancelAdvancedSearch = () => {
        setAdvancedFiltersModal(false);
        setSelectedPatient(null);
    };

    const onApplyClick = () => {
        setAdvancedFiltersModal(false);
        handleSearch()
    };

    const titleStyle = {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '17px',
        letterSpacing: '0em',
        textAlign: 'left'

    };

    const contentStyle = {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '15px',
        letterSpacing: '0em',
    };

    const getStatus = (tabValue, status) => {
        if (tabValue === 0) {
            if (status === 1) {
                return 'Confirmed';
            } else if (status === 2) {
                return 'Confirmed';
            }
            else if (status === 5) {
                return 'Cancelled by Patient';
            } else if (status === 6) {
                return 'Cancelled by Diagnostics';
            }
        } else {
            if (status === 1) {
                return 'Pending';
            } else if (status === 2) {
                return 'Delivered';
            } else if (status === 3) {
                return 'Dispatched';
            }
        };
    };

    const getStatusText = (statusValue) => {
        switch (statusValue) {
            case '0':
                return 'All';
            case '1':
                return tabValue === 0 ? 'Confirmed Order' : 'Pending';
            case '2':
                return 'Delivered';
            case '3':
                return 'Dispatched';
            case '5':
                return 'Cancelled by Patient';
            case '6':
                return 'Cancelled by Diagnostic';
            default:
                return '';
        }
    };

    const handleSort = (column) => {
        // If the same column is clicked, toggle the sort order
        const newOrder = sort.sortBy === column && sort.sortOrder === 'asc' ? 'desc' : 'asc';
        setSort({
            sortBy: column,
            sortOrder: newOrder,
        });
    };

    return (
        <>
            <Box p={3}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: '600' }}>
                        Orders Report
                    </Typography>
                    <div style={{ textAlign: 'right' }}>
                        <Button variant="contained" onClick={() => setAdvancedFiltersModal(true)} sx={{ borderRadius: "1.875rem" }}>+Advanced Filter</Button>
                    </div>
                </div>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    <Tab label="Orders Report" />
                    <Tab label="Delivery Report" />
                </Tabs>
                <Paper elevation={3} className="p-4 mb-4">
                    <Grid container spacing={2} className="lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 gap-4">
                        <Grid item xs={12} md={6} lg={2}>
                            <CustomDatePicker label="From Date" value={fromDate} onChange={handleFromDateChange} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <CustomDatePicker label="To Date" value={toDate} onChange={handleToDateChange} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <TextField label="Referred By" variant="outlined" size="small" style={{ width: '100%' }} onChange={handleReferredByChange} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel size="small">Status</InputLabel>
                                <Select value={status} onChange={handleStatusChange} label="Status" size="small">
                                    {tabValue === 0 ? (
                                        [
                                            <MenuItem key="all" value="0">All</MenuItem>,
                                            <MenuItem key="confirmedOrder" value="1">Confirmed Order</MenuItem>,
                                            <MenuItem key="cancelledByPatient" value="5">Cancelled by Patient</MenuItem>,
                                            <MenuItem key="cancelledByDiagnostic" value="6">Cancelled by Diagnostic</MenuItem>
                                        ]
                                    ) : (
                                        [
                                            <MenuItem key="all" value="0">All</MenuItem>,
                                            <MenuItem key="pending" value="1">Pending</MenuItem>,
                                            <MenuItem key="dispatched" value="3">Dispatched</MenuItem>,
                                            <MenuItem key="delivered" value="2">Delivered</MenuItem>
                                        ]
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} className="flex items-end">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                className="mt-2 md:mt-0"
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} className="flex items-end">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handlePrint}
                                className="mt-2 md:mt-0"
                            >
                                Print
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '25px', marginTop: '1rem' }}>
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="secondary" />
                    </div>
                    {/* {getDataForPage(page).map((data) => (
                        <Card key={data.id} variant="outlined" sx={{ flex: '1 0 calc(33.3% - 20px)', borderRadius: '10px' }}>
                            <CardContent>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={data?.status === 1 ? successIcon : cancelIcon}
                                            alt={data?.status === 1 ? 'Success' : 'Error'}
                                            style={{ marginRight: '10px' }}
                                        />
                                        <Typography variant="body1" component="div" display="flex" alignItems="center" style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            lineHeight: '21px',
                                            letterSpacing: '0em',
                                            textAlign: 'left'
                                        }}>
                                            {(() => {
                                                switch (data?.status) {
                                                    case 1:
                                                        return 'Confirmed';
                                                    case 2:
                                                        return 'Cancelled';
                                                    case 3:
                                                        return 'Cancelled by Eclinic';
                                                    default:
                                                        return 'Unknown Status';
                                                }
                                            })()}
                                        </Typography>
                                    </div>
                                    <Typography variant="body2" style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        lineHeight: '21px',
                                        letterSpacing: '0em',
                                        textAlign: 'left'
                                    }}>{data?.chehospitalName}</Typography>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            width: 50,
                                            height: 50,
                                            border: '1px solid #ccc',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: 10,
                                            marginRight: 10,
                                            background: 'white'
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ color: '#3D65FF' }}>
                                            {data?.patientName ? data?.patientName.charAt(0).toUpperCase() : 'U'}
                                        </Typography>
                                    </div>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                                                {`${data?.patientSalutation ? data?.patientSalutation + ' ' : ''}${data?.patientName}`}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <Typography variant="body2">{`Order ID: ${data?.orderId}`}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="body2" sx={titleStyle}>Date and Time:</Typography>
                                        <Typography variant="body2" sx={{ ...contentStyle, marginLeft: '10px' }}>{data?.confirmDateTime}</Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="body2" sx={titleStyle}>Revenue Source:</Typography>
                                        <Typography variant="body2" sx={{ ...contentStyle, marginLeft: '10px' }}>{data?.sourceChannel}</Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="body2" sx={titleStyle}>Referred By:</Typography>
                                        <Typography variant="body2" sx={{ ...contentStyle, marginLeft: '10px' }}>{data?.referredBy}</Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="body2" sx={titleStyle}>GST:</Typography>
                                        <Typography variant="body2" sx={{ ...contentStyle, marginLeft: '10px' }}>{data?.gst}</Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="body2" sx={titleStyle}>Total Amount:</Typography>
                                        <Typography variant="body2" sx={{ ...contentStyle, marginLeft: '10px' }}>{data?.paidAmount}</Typography>
                                    </div>
                                </div>
                            </CardContent>

                        </Card>
                    ))} */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> <TableSortLabel
                                        active={sort.sortBy === 'confirmDateTime'}
                                        direction={sort.sortOrder}
                                        onClick={() => handleSort('confirmDateTime')}
                                    >Date and Time
                                    </TableSortLabel></TableCell>
                                    <TableCell>  <TableSortLabel
                                        active={sort.sortBy === 'orderId'}
                                        direction={sort.sortOrder}
                                        onClick={() => handleSort('orderId')}
                                    >
                                        Order ID
                                    </TableSortLabel></TableCell>
                                    <TableCell> <TableSortLabel
                                        active={sort.sortBy === 'patientName'}
                                        direction={sort.sortOrder}
                                        onClick={() => handleSort('patientName')}
                                    >Patient Name
                                    </TableSortLabel></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sort.sortBy === 'chehospitalName'}
                                            direction={sort.sortOrder}
                                            onClick={() => handleSort('chehospitalName')}
                                        >Eclinic Name
                                        </TableSortLabel></TableCell>
                                    <TableCell> <TableSortLabel
                                        active={sort.sortBy === 'sourceChannel'}
                                        direction={sort.sortOrder}
                                        onClick={() => handleSort('sourceChannel')}
                                    >Revenue Source
                                    </TableSortLabel></TableCell>
                                    <TableCell><TableSortLabel
                                        active={sort.sortBy === 'referredBy'}
                                        direction={sort.sortOrder}
                                        onClick={() => handleSort('referredBy')}
                                    >Referred By
                                    </TableSortLabel></TableCell>
                                    <TableCell>GST</TableCell>
                                    <TableCell>Total Amount</TableCell>
                                    {tabValue === 0 ?
                                        <TableCell>Status</TableCell> :
                                        <TableCell>Delivery Status</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {getDataForPage(page).map((data, index) => ( */}
                                {print == true ? data.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data?.confirmDateTime}</TableCell>
                                        <TableCell>{data?.orderId}</TableCell>
                                        <TableCell>{data?.patientName}</TableCell>
                                        {/* <TableCell>{`${data?.patientSalutation ? data?.patientSalutation + ' ' : ''}${data?.patientName}`}</TableCell> */}
                                        <TableCell>{data?.chehospitalName}</TableCell>
                                        <TableCell>{data?.sourceChannel}</TableCell>
                                        <TableCell>{data?.referredBy}</TableCell>
                                        <TableCell>{data?.gst}</TableCell>
                                        <TableCell>{data?.totalAmount}</TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body1">
                                                    {tabValue === 0
                                                        ? getStatus(tabValue, data?.procedureStatus)
                                                        : getStatus(tabValue, data?.status)}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )) :
                                    getSortedData().map((data, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{data?.confirmDateTime}</TableCell>
                                            <TableCell>{data?.orderId}</TableCell>
                                            <TableCell>{data?.patientName}</TableCell>
                                            {/* <TableCell>{`${data?.patientSalutation ? data?.patientSalutation + ' ' : ''}${data?.patientName}`}</TableCell> */}
                                            <TableCell>{data?.chehospitalName}</TableCell>
                                            <TableCell>{data?.sourceChannel}</TableCell>
                                            <TableCell>{data?.referredBy}</TableCell>
                                            <TableCell>{data?.gst}</TableCell>
                                            <TableCell>{data?.totalAmount}</TableCell>
                                            <TableCell>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1">
                                                        {tabValue === 0
                                                            ? getStatus(tabValue, data?.procedureStatus)
                                                            : getStatus(tabValue, data?.status)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>
                {data?.length > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Pagination
                            count={Math.ceil(data.length / itemsPerPage)}
                            page={page}
                            color="secondary"
                            onChange={handlePageChange}
                        />
                    </Box>
                )}
            </Box >
            <Modal open={advancedFiltersModal} onClose={handleCloseAdancedFilterModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ width: '180vh', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Patient Search</Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseAdancedFilterModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent style={{ maxHeight: '50rem', overflowY: 'auto', position: 'relative' }}>
                            {patientLoading && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        zIndex: 1,
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px', overflowX: 'auto' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Patient Id"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.code}
                                            onChange={(e) => handleInputChange('code', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Patient First Name"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.firstName}
                                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Patient Last Name"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.lastName}
                                            onChange={(e) => handleInputChange('lastName', e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginTop: "0.5rem" }}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Mobile Number"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.mobile}
                                            onChange={(e) => handleInputChange('mobile', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.email}
                                            onChange={(e) => handleInputChange('email', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CustomDatePicker
                                            label="Date of Birth"
                                            value={advancedFiters.dob}
                                            onChange={(date) => handleInputChange('dob', date)}
                                        />
                                        <FormControlLabel sx={{ marginLeft: '1rem' }}
                                            control={
                                                <Checkbox
                                                    value={advancedFiters.status}
                                                    onChange={(e) => handleInputChange('status', e.target.checked)}
                                                />
                                            }
                                            label="Include Inactive"
                                        />
                                    </Grid>
                                </Grid>
                                <Button variant="contained" color="primary"  onClick={() => handleAdvancedSearch('P')} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                    Search
                                </Button>
                                <Button variant="contained" color="primary"  onClick={() => handleAdvancedSearch('F')} sx={{ borderRadius: '1rem', width: '15rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                    Search Family Member
                                </Button>
                            </Paper>
                            <div>
                                <Grid container>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Patient Id</TableCell>
                                                    <TableCell>First Name</TableCell>
                                                    <TableCell>Last Name</TableCell>
                                                    <TableCell>Gender</TableCell>
                                                    <TableCell>DOB</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Mobile</TableCell>
                                                    <TableCell>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {getPatientDataForPage(advancedSearchPage).map((rec, index) => (
                                                    <TableRow
                                                        key={index}
                                                        onClick={(e) => handleRowClick(e, rec)}
                                                        selected={selectedPatient === rec}
                                                    >
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={selectedPatient === rec}
                                                                onChange={() => { }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{rec?.code}</TableCell>
                                                        <TableCell>{rec?.firstName}</TableCell>
                                                        <TableCell>{rec?.lastName}</TableCell>
                                                        <TableCell>{rec?.gender === 'M' ? 'Male' : 'Female'}</TableCell>
                                                        <TableCell>{rec?.dob}</TableCell>
                                                        <TableCell>{rec?.email}</TableCell>
                                                        <TableCell>{rec?.mobile}</TableCell>
                                                        <TableCell>{rec?.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </div>
                            {patientDetails?.length > 0 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                                    <Pagination
                                        count={Math.ceil(patientDetails.length / patientDetailsPerPage)}
                                        page={advancedSearchPage}
                                        color="secondary"
                                        onChange={handlePatientPageChange}
                                    />
                                </Box>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                <Button style={{ marginLeft: '1rem' }} onClick={onCancelAdvancedSearch}>Cancel</Button>
                                <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={onApplyClick}>Apply</Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={printModal} onClose={handleClosePrintModal}>
                <div className=" flex items-center justify-center">
                    <Card className=" overflow-scroll">
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>{tabValue === 0 ? 'Orders Report' : 'Delivery Report'}</Typography>
                            }
                            action={
                                <IconButton onClick={handleClosePrintModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent >
                            <div className='flex flex-row'>
                                <div className='mr-4'>
                                    <Typography >From Date: {fromDate.format('YYYY-MM-DD')}</Typography>
                                </div>
                                <div className='mr-4'>
                                    <Typography>To Date: {toDate.format('YYYY-MM-DD')}</Typography>
                                </div>
                                <div className='mr-4'>
                                    <Typography>Patient: {selectedPatient ? selectedPatient?.firstName : 'All'}</Typography>
                                </div>
                                <div className='mr-4'>
                                    <Typography >Referred By: {referredBy ? referredBy : 'All'}</Typography>
                                </div>
                                <div className='mr-4'>
                                    <Typography>Status: {getStatusText(status)}</Typography>
                                </div>
                                {/* <IconButton color="secondary">
                                    <ReactToPrint
                                        trigger={() => <IconButton color="secondary"><PrintIcon /></IconButton>}
                                        content={() => tableRef.current}
                                    />
                                </IconButton> */}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => window.print()}
                                    className="print-button"
                                >
                                    Print
                                </Button>

                            </div>
                        </CardContent>

                        <CardContent style={{ maxHeight: '90vh', overflowY: 'auto', maxWidth: '120vh' }}>
                            <div className=" overflow-x-auto">
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Date and Time</TableCell>
                                                <TableCell>
                                                    Order ID
                                                </TableCell>
                                                <TableCell>Patient Name
                                                </TableCell>
                                                <TableCell>
                                                    Eclinic Name
                                                </TableCell>
                                                <TableCell> Revenue Source
                                                </TableCell>
                                                <TableCell>Referred By
                                                </TableCell>
                                                <TableCell>GST</TableCell>
                                                <TableCell>Total Amount</TableCell>
                                                {tabValue === 0 ?
                                                    <TableCell>Status</TableCell> :
                                                    <TableCell>Delivery Status</TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data.map((data, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{data?.confirmDateTime}</TableCell>
                                                        <TableCell>{data?.orderId}</TableCell>
                                                        <TableCell>{data?.patientName}</TableCell>
                                                        {/* <TableCell>{`${data?.patientSalutation ? data?.patientSalutation + ' ' : ''}${data?.patientName}`}</TableCell> */}
                                                        <TableCell>{data?.chehospitalName}</TableCell>
                                                        <TableCell>{data?.sourceChannel}</TableCell>
                                                        <TableCell>{data?.referredBy}</TableCell>
                                                        <TableCell>{data?.gst}</TableCell>
                                                        <TableCell>{data?.totalAmount}</TableCell>
                                                        <TableCell>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography variant="body1">
                                                                    {tabValue === 0
                                                                        ? getStatus(tabValue, data?.procedureStatus)
                                                                        : getStatus(tabValue, data?.status)}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>

        </>
    );
};

export default Reports;
