import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Card, Chip, Paper } from '@mui/material';
import { useNavigate } from "react-router";
import {
    Typography,
    Button,
} from "@mui/material";
import apiService from "../../services/actions";
import { Password } from "@mui/icons-material";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import { toast } from 'react-toastify';  
function InputWithIcon({ type, onChange, place }) {
    const [showPassword, setShowPassword] = useState(false);




    return (
        <div className="relative">
            <input
                type={type === "password" ? (showPassword ? "text" : "password") : type}
                onChange={onChange}
                placeholder={place}
                required
                className="w-96  py-2 text-start  pl-3 border rounded-sm shadow-sm focus:outline-none focus:bg-password-gray  sm:text-sm "
            />
            {type === "password" && (
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                    {showPassword ? (
                        <VisibilityIcon className="h-5 w-5" />
                    ) : (
                        <VisibilityOffIcon className="h-5 w-5" />
                    )}
                </button>
            )}
        </div>
    );
}
const ChangePassword = (props) => {
    const loginUser = JSON.parse(localStorage.getItem('loginObj'))

    const navigate = useNavigate();
    const [pswd, setPswd] = useState()
    const [pass, setPass] = useState()
    const [passChange, setPassChange] = useState()
    const [oldPass, setOldPass] = useState()
    const [err, setErr] = useState()
    const [notSame, setNotSame] = useState()

    const handleUpdate = async () => {
        if (!oldPass) {
            return toast.error("Please enter Old password .", {
                position: toast.POSITION.TOP_CENTER,
            });  
        }

        else if (!passwordCriteria(oldPass)) {
            return toast.error("Old Password filed must contains Minimum 6 letters, Minimum 1 Capital letter, 1 small letter, 1 number, 1 special Character '.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        if (!pass) {
            return toast.error("Please enter new password .", {
                position: toast.POSITION.TOP_CENTER,
            }); 
            
        }

        else if (!passwordCriteria(pass)) {
            return toast.error("New Password filed must contains Minimum 6 letters, Minimum 1 Capital letter, 1 small letter, 1 number, 1 special Character .", {
                position: toast.POSITION.TOP_CENTER,
            })
        }

        if (!passChange) {
            return toast.error("Please enter confirm Password.", {
                position: toast.POSITION.TOP_CENTER,
            })
        }

        else if (!passwordCriteria(passChange)) {
            return toast.error("Confirm Password filed must contains Minimum 6 letters, Minimum 1 Capital letter, 1 small letter, 1 number, 1 special Character.", {
                position: toast.POSITION.TOP_CENTER,
            })
        }

        if (pass != passChange) {
            return toast.error("Password and Confirm Password does not match", {
                position: toast.POSITION.TOP_CENTER,
            })
        }

        let payload = {
            "currentPasswordVal": btoa(passChange),
            "passWordVal": btoa(oldPass),
            "passwordvall": passChange,
            "modifiedBy": loginUser.user.code,
            "createdBy": loginUser.user.code,
            "createdDate": "2023-11-16 07:48:23",
            "modifiedDate": "2023-11-16 07:48:23",
            "userCode": loginUser.user.code,
            "status": 1
        }
        try {
            const ordersDetailsResponse = await apiService.changePassword(payload);
            // setLoading(false)
            if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                 toast.error("Password Updateted Successfully.", {
                    position: toast.POSITION.TOP_CENTER,
                })
                props.onClose()
            } else {
                console.error('API error:', ordersDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    }
    const passwordCriteria = (password) => {
        var re = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,12}$/;
        return re.test(password);
    }
    const cancel = () => {
        props.onClose()
    }
    return (
        <>
            <Card variant="outlined" elevation={1} sx={{
                boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)", paddingX: '2%', height: '30rem', borderRadius: '5px'
            }} >

                <div className='mx-3 my-6  flex justify-start  border-b-2 '>
                    <div>
                        <p className='text-brand-lightBlack text-lg font-bold'>Change Your Password</p>
                    </div>

                </div>
                <div className="grid justify-start px-8 pl-4  ">
                    <div className="grid py-1">
                        <label className=" text-brand-lightBlack pl-1 pt-2 pb-1">
                            {" "}
                            Old Password
                        </label>
                        <InputWithIcon
                            type="password"
                            place="Type Old Password"
                            onChange={(e) => setOldPass(e?.target?.value)}


                        />
                    </div>
                    <div className="grid py-1">
                        <label className=" text-brand-lightBlack pl-1 pt-2 pb-1">
                            {" "}
                            New Password
                        </label>
                        <InputWithIcon
                            type="password"
                            place="Type New Password"
                            onChange={(e) => setPass(e?.target?.value)}


                        />
                    </div>
                    <div className="grid py-1">
                        <label className=" text-brand-lightBlack pl-1 pt-2 pb-1">
                            {" "}
                            Confirm Password
                        </label>
                        <InputWithIcon
                            type="password"
                            place="Type Confirm Password"
                            onChange={(e) => setPassChange(e?.target?.value)}

                        />
                    </div>

                    <Button sx={{ marginTop: "1rem" }} variant="contained" onClick={handleUpdate}  >Change Password</Button>
                    <Button sx={{ marginTop: "1rem" }} variant="contained" onClick={cancel}  >Cancel</Button>


                </div>
                {/* <Card variant="outlined" elevation={1} sx={{ boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)", width: '60rem', paddingX: '1%', height: '20rem', borderRadius: '7px' }} >
                                        <div>
                                            <div className='mx-3 my-6  flex justify-start  border-b-2 '>
                                                <div>
                                                    <p className='text-brand-lightBlack text-lg font-bold pb-3'>Password must contain:</p>
                                                </div>


                                            </div>
                                            <div className="pl-6">
                                                <li >Must contain a min of 8 and max of 16 character</li>
                                                <li className="mb-3 mt-3">Must contain at least one uppercase and a numeral</li>
                                                <li>Must contain at least one special character</li>
                                            </div>
                                        </div>



                                    </Card> */}
            </Card>
        </>
    )
}

export default ChangePassword;
