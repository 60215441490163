import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, Typography, Paper, Grid, Button, Modal, TextField } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Email, Logout, Person, Phone } from '@mui/icons-material';

import curebayIcon from "../../assets/images/curebayIcon.svg"
import dashboardIcon from "../../assets/images/dashboardIcon.svg";
import dashboardActiveIcon from "../../assets/images/dashboardActiveIcon.svg";
import receivedOrdersIcon from "../../assets/images/receivedOrdersIcon.svg";
import receivedOrdersActiveIcon from "../../assets/images/receivedOrdersActiveIcon.svg";
import allOrdersIcon from "../../assets/images/allOrdersIcon.svg";
import allOrdersActiveIcon from "../../assets/images/allOrdersActiveIcon.svg";
import reportsIcon from "../../assets/images/reportsIcon.svg";
import reportsActiveIcon from "../../assets/images/reportsActiveIcon.svg";
import BELL from "../../assets/images/bell.svg";
import mail from "../../assets/images/mail.svg";

import Reports from './reports';
import Orders from './orders';
import ReceivedOrder from './receivedOrder';
import Dashboard from './dashboard';
import CompletedOrders from './completedorders';
import PendingOrders from './pendingorders';
import ChangePassword from '../login/changePassword';
import UserProfile from '../login/userProfile';
import Notification from './notification';
import Mail from './mail';
import CollectionList from './colllectionList';
import { APP_ROUTES } from '../../routes/approutes';
import apiService from '../../services/actions';
import { formatToCurrentDateTime } from '../common';
import DiagnosticsList from './diognosticsList';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, selectedMenu }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 'auto',
      },
      '& .MuiListItemText-root': {
        display: 'none',
      },
    }),
  }),
);

const MenuListItem = styled(ListItem)(({ theme, selected }) => ({
  backgroundColor: selected == true ? '#E1F4FF' : 'transparent',
  cursor: 'pointer',
  color: selected == true ? '#1C6CE5' : 'inherit',
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: '#E1F4FF',
  },
}));

export default function HomePage() {
  const loginUser = JSON.parse(localStorage.getItem('loginObj'));
  const currentDateTime = formatToCurrentDateTime(new Date());
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [changePasswordmodal, setchangePasswordmodal] = useState(false);
  const [Notificationmodal, setNotificationmodal] = useState(false);
  const [myProfilemodal, setmyProfilemodall] = useState(false);
  const [mailModal, setmailModal] = useState(false);
  const [feedbackModal, setfeedbackModal] = useState(false);

  const location = useLocation();
  const path = location.pathname;
  const activeMenu = path.split('/').pop() || 'Dashboard';
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedback, setfeedback] = useState({
    subject: '',
    feedback: '',
  });

  const handleInputChange = (field, value) => {
    setfeedback({
      ...feedback,
      [field]: value,
    });
  };

  const sentFeedback = async () => {
    let payload = {
      "subject": feedback.subject,
      "content": feedback.feedback,
      "modifiedDate": currentDateTime,
      "modifiedBy": loginUser.user.code,
      "createdDate": currentDateTime,
      "createdBy": loginUser.user.code,
      "fromCode": loginUser.user.code,
      "status": 1
    };
    try {
      const res = await apiService.sentFeedback(payload);
      if (res.status === 200 && res?.data) {
        setfeedbackModal(false);
      } else {
        console.error('API error:', res.statusText);
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (menu) => {
    switch (menu) {
      case 'Dashboard':
        navigate(APP_ROUTES.DASHBOARD);
        break;
      case 'Received Orders':
        navigate(APP_ROUTES.RECEIVEDORDERS);
        break;
      case 'All Orders':
        navigate(APP_ROUTES.ALLORDERS);
        break;
      case 'Completed Orders':
        navigate(APP_ROUTES.COMPLETEDORDERS);
        break;
      case 'Pending Orders':
        navigate(APP_ROUTES.PENDINGORDERS);
        break;
      case 'Reports':
        navigate(APP_ROUTES.REPORTS);
        break;
      case 'Lab Details':
        navigate(APP_ROUTES.LABLIST);
        break;
      case 'SM Collections':
        navigate(APP_ROUTES.COLLECTION);
        break;
      default:
        break;
    }
  };
  

  const openChangepassword = (e) => {
    if (e === 'CP') {
      setchangePasswordmodal(true);
    } else if (e === 'UP') {
      setmyProfilemodall(true);
    } else if (e === 'NO') {
      setNotificationmodal(true);
    } else if (e === 'MA') {
      setmailModal(true);
    } else if (e === 'FB') {
      setfeedbackModal(true);
    }
  };

  const handleCloseAdancedFilterModal = () => {
    setchangePasswordmodal(false);
    setmyProfilemodall(false);
    setNotificationmodal(false);
    setmailModal(false);
    setfeedbackModal(false);
  };

  const MenuList = [
    { name: 'Dashboard', icon: dashboardIcon, url:'dashboard', activeIcon: dashboardActiveIcon },
    { name: 'Received Orders', icon: receivedOrdersIcon , url:'receivedOrders', activeIcon: receivedOrdersActiveIcon },
    { name: 'Completed Orders', icon: allOrdersIcon , url:'completedOrders', activeIcon: allOrdersActiveIcon },
    { name: 'Pending Orders', icon: allOrdersIcon , url:'pendingOrders', activeIcon: allOrdersActiveIcon },
    { name: 'Reports', icon: reportsIcon , url:'reports', activeIcon: reportsActiveIcon },
    { name: 'Lab Details', icon: reportsIcon , url:'lablist', activeIcon: reportsActiveIcon },
    { name: 'SM Collections', icon: reportsIcon , url:'collection', activeIcon: reportsActiveIcon }
  ];

  const renderMenuListItem = (menu, index) => {
    const isSelected = activeMenu.toLowerCase() === menu.url.toLowerCase();
    return (
      <MenuListItem key={index} selected={isSelected} onClick={() => handleMenuClick(menu.name)}>
        <ListItemIcon>
          <img src={isSelected == true ? menu.activeIcon : menu.icon} alt={menu.name} />
        </ListItemIcon>
        <ListItemText primary={menu.name} />
      </MenuListItem>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{ marginRight: '36px', ...(open && { display: 'none' }) }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <img src={curebayIcon} alt="CureBay" width={100} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" onClick={() => openChangepassword('NO')}>
              <img src={BELL} alt="Notifications" />
            </IconButton>
            <IconButton color="inherit" onClick={() => openChangepassword('MA')}>
              <img src={mail} alt="Mail" />
            </IconButton>
            <IconButton color="inherit" onClick={handleClick}>
              <Avatar alt="User" src="/static/images/avatar/1.jpg" />
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Paper sx={{ padding: 2 }}>
                <Typography variant="subtitle1">{loginUser.user.firstName}</Typography>
                <Typography variant="subtitle2" color="textSecondary">{loginUser.user.email}</Typography>
                <Typography variant="subtitle2" color="textSecondary">{loginUser.user.phoneNumber}</Typography>
                <MenuItem onClick={() => openChangepassword('UP')}>
                  <Person /> Profile
                </MenuItem>
                <MenuItem onClick={() => openChangepassword('CP')}>
                  <Phone /> Change Password
                </MenuItem>
                <MenuItem onClick={() => openChangepassword('FB')}>
                  <FeedbackIcon /> Feedback
                </MenuItem>
                <MenuItem onClick={() => {
                  localStorage.removeItem('loginObj');
                  navigate('/login');
                }}>
                  <Logout /> Log out
                </MenuItem>
              </Paper>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {MenuList.map((menu, index) => renderMenuListItem(menu, index))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {activeMenu.toLowerCase().replace(" ", "") === 'dashboard' && <Dashboard />}
                        {activeMenu.toLowerCase().replace(" ", "") === 'receivedorders' && <ReceivedOrder />}
                        {/* {activeMenu.toLowerCase().replace(" ", "") === 'allorders' && <Orders />} */}
                        {activeMenu.toLowerCase().replace(" ", "") === 'completedorders' && <CompletedOrders />}
                        {activeMenu.toLowerCase().replace(" ", "") === 'pendingorders' && <PendingOrders />}
                        {activeMenu.toLowerCase().replace(" ", "") === 'reports' && <Reports />}
                        {activeMenu.toLowerCase().replace(" ", "") === 'lablist' && <DiagnosticsList />}
        {activeMenu.toLowerCase() === 'collection' && <CollectionList />}
      </Box>

      {/* Modals */}
      <Modal open={changePasswordmodal} onClose={handleCloseAdancedFilterModal}>
        <ChangePassword />
      </Modal>
      <Modal open={myProfilemodal} onClose={handleCloseAdancedFilterModal}>
        <UserProfile />
      </Modal>
      <Modal open={Notificationmodal} onClose={handleCloseAdancedFilterModal}>
        <Notification />
      </Modal>
      <Modal open={mailModal} onClose={handleCloseAdancedFilterModal}>
        <Mail />
      </Modal>
      <Modal open={feedbackModal} onClose={handleCloseAdancedFilterModal}>
        <Paper sx={{ padding: 4 }}>
          <Typography variant="h6">Feedback</Typography>
          <TextField
            fullWidth
            label="Subject"
            value={feedback.subject}
            onChange={(e) => handleInputChange('subject', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Feedback"
            value={feedback.feedback}
            onChange={(e) => handleInputChange('feedback', e.target.value)}
            margin="normal"
            multiline
            rows={4}
          />
          <Button variant="contained" color="primary" onClick={sentFeedback} sx={{ marginTop: 2 }}>
            Send
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
}
