import React, { useState, useEffect, useCallback } from 'react';

import {
    TextField,
    Button,
    Grid,
    Card,
    CardContent,
    CardActions,
    IconButton,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CardHeader,
    Collapse,
    Typography,
    Box,
    Pagination,
    CircularProgress,
    Modal,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import CustomDatePicker from '../common/customdatepicker';
import apiService from '../../services/actions';
import { formatDateToMMDDYYYY, formateDateToDDMMYYYYHHMM } from '../common';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';

const PatientSearch = (props) => {
    const [filters, setFilters] = useState({
        fromDate: dayjs().subtract(7, 'day'),
        toDate: dayjs(),
        status: 1,
    });
    const [orderDetails, setOrderDetails] = useState([]);
    const [expanded, setExpanded] = useState(-1);
    const [hospitalId, setHospitalId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [patientLoading, setPatientLoading] = useState(false);
    const [patientDetails, setPatientDetails] = useState([]);
    const [page, setPage] = useState(1);
    const [advancedSearchPage, setAdvancedSearchPage] = useState(1);
    const itemsPerPage = 3;
    const patientDetailsPerPage = 3;
    const [advancedFiters, setAdvancedFilters] = useState({
        parentId: '',
        patientFirstName: '',
        patientLastName: '',
        mobileNumber: '',
        email: '',
        dateOfBirth: null
    });
    const [selectedPatient, setSelectedPatient] = useState(null);

    const parseLocationData = (locationData) => {
        try {
            const data = JSON.parse(locationData);
            const { hospitalId, locationId } = data;
            setHospitalId(hospitalId);
            setLocationId(locationId);
        } catch (error) {
            console.error('Error parsing location data:', error);
        }
    };

    useEffect(() => {
        const locationData = localStorage.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            parseLocationData(locationData);
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleExpandClick = (index) => {
        setExpanded(expanded === index ? -1 : index);
    };

    const handleInputChange = (field, value) => {
        setAdvancedFilters({
            ...advancedFiters,
            [field]: value,
        });
    };

    const handleSearch = async () => {
        const payload = {
            procedureStatus: filters?.status === 1 ? 0 : filters?.status,
            fromDate1: filters?.fromDate ? formatDateToMMDDYYYY(filters?.fromDate) : undefined,
            toDate1: filters?.toDate ? formatDateToMMDDYYYY(filters?.toDate) : undefined,
            hospitalId: hospitalId ? hospitalId : undefined,
            locationId: locationId ? locationId : undefined,
            status: filters?.status,
            patientId: selectedPatient ? selectedPatient?.code : undefined,
            orderDetailsRequired: 'Y',

        };
        try {
            const ordersDetailsResponse = await apiService.getOrderDetails(payload);
            if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                setOrderDetails(ordersDetailsResponse.data)
            } else {
                console.error('API error:', ordersDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };

    const getDataForPage = useCallback((page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const dataForPage = orderDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [orderDetails, itemsPerPage]);

    const getPatientDataForPage = useCallback((advancedSearchPage) => {
        const startIndex = (advancedSearchPage - 1) * patientDetailsPerPage;
        const endIndex = startIndex + patientDetailsPerPage;
        const dataForPage = patientDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [patientDetailsPerPage, patientDetails])

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePatientPageChange = (event, value) => {
        setAdvancedSearchPage(value);
    }
    useEffect(() => {
        getDataForPage(page);
    }, [page, getDataForPage]);

    useEffect(() => {
        getPatientDataForPage(advancedSearchPage);
    }, [advancedSearchPage, getPatientDataForPage]);

    const handleCloseAdancedFilterModal = () => {
        setSelectedPatient(null);
        props.onClose()
    };

    const onCancelAdvancedSearch = () => {
        setSelectedPatient(null);
        props.onClose()
    };

    const onApplyClick = () => {
        props.onClose(selectedPatient)
    };

    const handleAdvancedSearch = async () => {
        setPatientLoading(true)
        const payload = {
            code: advancedFiters?.code ? advancedFiters.code : undefined,
            firstName: advancedFiters?.firstName ? advancedFiters?.firstName : undefined,
            lastName: advancedFiters?.lastName ? advancedFiters.lastName : undefined,
            mobile: advancedFiters?.mobile ? advancedFiters.mobile : undefined,
            email: advancedFiters?.email ? advancedFiters.email : undefined,
            dob: advancedFiters?.dob ?formatDateToMMDDYYYY(advancedFiters?.dob) : undefined,
            status: 1,

        };
        try {
            const patientDetailsResponse = await apiService.getPatientDetails(payload);
            setPatientLoading(false)
            if (patientDetailsResponse.status === 200 && patientDetailsResponse?.data) {
                setPatientDetails(patientDetailsResponse.data)
            } else {
                console.error('API error:', patientDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };

    const handleRowClick = (event, row) => {
        event.stopPropagation();
        setSelectedPatient((prevSelected) => {
            if (prevSelected === row) {
                return null;
            } else {
                return row;
            }
        });
    };
    return (
        <>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
<Card style={{ width: '180vh', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
    <CardHeader
        title={
            <Typography variant="h6" style={{
                fontSize: '18px',
                fontWeight: 500,
            }}>Patient Search</Typography>
        }
        action={
            <IconButton onClick={handleCloseAdancedFilterModal}>
                <CloseIcon />
            </IconButton>
        }
        style={{
            backgroundColor: '#f5f5f5',
            borderBottom: '1px solid #e0e0e0',
        }}
    />
    <Divider />
    <CardContent style={{ maxHeight: '50rem', overflowY: 'auto', position: 'relative' }}>
        {patientLoading && (
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                }}
            >
                <CircularProgress />
            </Box>
        )}
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px', overflowX: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Parent Id"
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={advancedFiters.code}
                        onChange={(e) => handleInputChange('code', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Patient First Name"
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={advancedFiters.firstName}
                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Patient Last Name"
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={advancedFiters.lastName}
                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: "0.5rem" }}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={advancedFiters.mobile}
                        onChange={(e) => handleInputChange('mobile', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={advancedFiters.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomDatePicker
                        label="Date of Birth"
                        value={advancedFiters.dob}
                        onChange={(date) => handleInputChange('dob', date)}
                    />
                    <FormControlLabel sx={{ marginLeft: '1rem' }}
                        control={
                            <Checkbox
                                value={advancedFiters.status}
                                onChange={(e) => handleInputChange('status', e.target.checked)}
                            />
                        }
                        label="Include Inactive"
                    />
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" onClick={handleAdvancedSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                Search
            </Button>
            <Button variant="contained" color="primary" onClick={handleAdvancedSearch} sx={{ borderRadius: '1rem', width: '15rem', marginTop: '1rem', marginLeft: '1rem' }}>
                Search Family Member
            </Button>
        </Paper>
        <div>
            <Grid container>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Patient Id</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>DOB</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getPatientDataForPage(advancedSearchPage).map((rec, index) => (
                                <TableRow
                                    key={index}
                                    onClick={(e) => handleRowClick(e, rec)}
                                    selected={selectedPatient === rec}
                                >
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedPatient === rec}
                                            onChange={() => { }}
                                        />
                                    </TableCell>
                                    <TableCell>{rec?.code}</TableCell>
                                    <TableCell>{rec?.firstName}</TableCell>
                                    <TableCell>{rec?.lastName}</TableCell>
                                    <TableCell>{rec?.gender === 'M' ? 'Male' : 'Female'}</TableCell>
                                    <TableCell>{rec?.dob}</TableCell>
                                    <TableCell>{rec?.email}</TableCell>
                                    <TableCell>{rec?.mobile}</TableCell>
                                    <TableCell>{rec?.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </div>
        {patientDetails?.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                <Pagination
                    count={Math.ceil(patientDetails.length / patientDetailsPerPage)}
                    page={advancedSearchPage}
                    color="secondary"
                    onChange={handlePatientPageChange}
                />
            </Box>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button style={{ marginLeft: '1rem' }} onClick={onCancelAdvancedSearch}>Cancel</Button>
            <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={onApplyClick}>Apply</Button>
        </div>
    </CardContent>
</Card>
</div>
    </>
    );
  };
  
  export default PatientSearch;
  