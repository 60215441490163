import axios from "axios";
// import { secureStorage } from "../reducer/authreducer";
import { toast } from "react-toastify";

let is403Error = false;
var axiosInstance = axios.create({
    // baseURL:process.env.REACT_AD_LOGIN_BASEURL,
    headers: {
        "Content-type": "application/json",
    }
});

const navigateToLogin = () => {
    // secureStorage.removeItem('token'); 
    localStorage.clear();
    window.location.href = "/";
};

axiosInstance.interceptors.request.use(
    config => {
        let s_token = localStorage.getItem('access_token');
        if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${s_token}`;
        }
        return config;

    },
    error => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 403) {
            if (!is403Error) {
                is403Error = true;
                toast.warn("Your session has ended. Please log in again to continue.", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: navigateToLogin,
                });
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
