import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CustomDatePicker({ label, value, onChange }) {
  const isError = !value;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        slotProps={{ textField: { size: 'small' } }}
        sx={{ minWidth: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={isError}
          />
        )}
      />
    </LocalizationProvider>
  );
}
