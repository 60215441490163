import httpcommon from "../httpcommon";
import adlogin from '../adlogin'
import axios from "axios";
import { encryptData } from "../../components/common/encryptDecrypt"
class ApiService {
//     async getToken(formData, OTP) {
//         try {
//             const { userCode, userMobileNumber, passWord, userOTP } = formData;

//             const params = new URLSearchParams();
//             params.append('username', userCode || userMobileNumber);

//             let passwordValue;

//             if (passWord !== undefined && passWord !== null && passWord !== '') {
//                 passwordValue = btoa(passWord);
//             } else if (userOTP !== undefined && userOTP !== null && userOTP !== '') {
//                 passwordValue = userOTP;
//             } else {

//                 passwordValue = OTP;
//             }

//             params.append('password', passwordValue);
//             params.append('grant_type', 'password');

//             const credentials = 'curebay:cGFzc3dvcmQ=';
//             const encodedCredentials = window.btoa(credentials);
//             console.log(params)
//             let payload1={
//                 username: userCode,
//                 passWord:passwordValue
//             }
// let payload = encryptData(payload1)
//             const response = await httpcommon.post('/gcp/oauth/token', payload, {
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//                     'grant_type': 'password',
//                     'Authorization': `Basic ${encodedCredentials}`
//                 },
//             });

//             return response || null;
//         } catch (error) {
//             console.error('API Request Error:', error);
//             return null;
//         }
//     }
async getToken(formData) {
    console.log(formData)
    formData.grant_type= 'password'
    delete formData.userMobileNumber
    delete formData.userOTP
    // formData.passWord= window.btoa(formData.Password)
    let payload ={
        userCode:formData.userCode,
        passWord:window.btoa(formData.passWord),
        channel:'W'
    }

    const encryptedData=encryptData(payload)
    return httpcommon.post('gcp/oauth/token', encryptedData)


}

    async loginApi(formData) {
        const parsedFormData = JSON.parse(formData);

        const filteredFormData = Object.fromEntries(
            Object.entries(parsedFormData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
        );
        if (filteredFormData.passWord) {
            filteredFormData.passWord = btoa(filteredFormData.passWord);
            filteredFormData.roleCode = 'DIAG'
        }
        console.log(formData)
        delete filteredFormData.grant_type

        let payload = encryptData(filteredFormData)
        // payload.grant_type

        try {
            // const response = await adlogin.post('https://adminapi-stage.curebay.in/admin/Login/', JSON.stringify(filteredFormData));
            const response = await httpcommon.post('Login/withOutAd',payload);

            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async logincbayuser(formData) {
        const parsedFormData = JSON.parse(formData);

        const filteredFormData = Object.fromEntries(
            Object.entries(parsedFormData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
        );
        if (filteredFormData.passWord) {
            filteredFormData.passWord = btoa(filteredFormData.passWord);
        }

        try {
            const response = await httpcommon.post('Login/withAd', JSON.stringify(filteredFormData));
            console.log(response)
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async adloginApi(formData) {
        const parsedFormData = formData

        const filteredFormData = Object.fromEntries(
            Object.entries(parsedFormData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
        );
        if (filteredFormData.passWord) {
            filteredFormData.password = filteredFormData.passWord
        }
        filteredFormData.username = filteredFormData.userCode
        delete filteredFormData.userCode
        delete filteredFormData.passWord
        try {
            const response = await adlogin.post('https://adapi-stage.curebay.in/commonlogin/api/validateUser', JSON.stringify(filteredFormData));

            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }


    }
    async loginUsingMobile(formData) {
        const parsedFormData = JSON.parse(formData);
        if (parsedFormData.otp) {
            parsedFormData.passWord = btoa(parsedFormData.passWord);
        }

        try {
            const response = await httpcommon.post('/Login/UserLoginWithMobile/', JSON.stringify(parsedFormData));
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async changePassword(formData) {


        try {
            const response = await httpcommon.post('/PasswordMaintenance/', formData);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async sentFeedback(formData) {


        try {
            const response = await httpcommon.post('/Feedback/', formData);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async saveLab(formData) {


        try {
            const response = await httpcommon.post('hospital/', formData);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async updateLab(formData) {


        try {
            const response = await httpcommon.put('hospital/'+formData.id, formData);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async userInfo(userCode) {
        try {
            const params = new URLSearchParams();

            params.append('userId', userCode);
            params.append('status', 1);
            const response = await httpcommon.get(`/User_Hospital_Location/list?${params.toString()}`, {})
            if (response) {
                return response;
            }
            else {
                console.error('API Error:', response);
                return null;
            }
        }
        catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async getOrderDetails(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`/PatientLabTestsOrder/list?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async validManualId(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });
        const response = await httpcommon.post(`/manualBooking/validation`, filterDetails);

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async updateManualId(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });
        const response = await httpcommon.post(`/manualBooking/`, filterDetails);

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getPatientDetails(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`/patient/list/filter?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async b2bCount() {
        try {
            // const params = new URLSearchParams();

            // const filterKeys = Object.keys(details);

            // filterKeys.forEach(key => {
            //     const value = details[key];
            //     if (value !== undefined && value !== null) {
            //         params.append(key, value);
            //     }
            // });

            const response = await httpcommon.get(`/Dashboard/b2bAndB2CCount`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async failureBooking(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });


            const response = await httpcommon.get(`Dashboard/listCountFailureBooking?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    
    async labPartnerCount(details) {
        try {
        const params = new URLSearchParams();

            const filterKeys = Object.keys(details);

            filterKeys.forEach(key => {
                const value = details[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });
            const response = await httpcommon.get(`Dashboard/labPartnerCount?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async reportFailure(details) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(details);

            filterKeys.forEach(key => {
                const value = details[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });
            const response = await httpcommon.get(`Dashboard/listCountFailureReport?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getLabTestsOrderReport(details) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(details);

            filterKeys.forEach(key => {
                const value = details[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`/LabTestsOrderReport/list/filter?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getPatientLabTestsOrderDetails(details) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(details);

            filterKeys.forEach(key => {
                const value = details[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`/PatientLabTestsOrderDetails/list/filter?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getStatelist(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`state/list?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async updateProfile(formData) {


        try {
            const response = await httpcommon.put(`user/${formData.id}`, formData);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getUserDetails(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`user/${filterDetails.code}`);

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async uploadLabReports(payload, method) {
        try {
            let response;
            if (method === 'POST') {
                response = await httpcommon.post('/LabTestsOrderReport/', payload);
            } else if (method === 'PUT') {
                response = await httpcommon.put('/LabTestsOrderReport/', payload);
            } else {
                console.error('Invalid HTTP method:', method);
                return null;
            }
            if (response) {
                return response
            }
            else {
                console.error('API Error:', response);
                return null;
            }
        }
        catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async uploadLabReportsLog(payload) {
        try {
            let response;
                response = await httpcommon.post('/PatientLabReportLog/', payload);
    
            if (response) {
                return response
            }
            else {
                console.error('API Error:', response);
                return null;
            }
        }
        catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getNotification(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            // const response = await httpcommon.get(`notification/list/filter/${filterDetails.trigeredTo}`);
            const response = await httpcommon.get(`notification/list/filter?${params.toString()}`, {});


            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async postNotification(filterDetails) {
        try {
            const response = await httpcommon.post('/notification/', filterDetails);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async forgetPassword(filterDetails) {
        try {
            const response = await httpcommon.post('PasswordMaintenance/forgetpassword/', filterDetails);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async patientLabTestsOrder(payload, method) {
        try {
            let response;
            if (method === 'POST') {
                response = await httpcommon.post('/PatientLabTestsOrder/', payload);
            } else if (method === 'PUT') {
                response = await httpcommon.put('/PatientLabTestsOrder/' + payload.orderId, payload);
            } else {
                console.error('Invalid HTTP method:', method);
                return null;
            }
            if (response) {
                return response
            }
            else {
                console.error('API Error:', response);
                return null;
            }
        }
        catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getsentMail(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`mails/listSentMailsFromUser?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getLabs(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`hospital/list/filter?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getCollectionList(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails ||{});

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`SMDetails/list?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getLabList(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails ||{});

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`hospital/listAllLabName?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getInboxMail(filterDetails) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(filterDetails);

            filterKeys.forEach(key => {
                const value = filterDetails[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`mails/listInboxMailsForUser?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async getCountDetails(details) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(details);

            filterKeys.forEach(key => {
                const value = details[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`/LabDashboard/listCount?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async postMails(filterDetails) {
        try {
            const response = await httpcommon.post('/mails/', filterDetails);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async getLabOrderGraphDetails(details) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(details);

            filterKeys.forEach(key => {
                const value = details[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });

            const response = await httpcommon.get(`/LabDashboard/listCompletedPending?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async getSLAComplianceGraphDetails(details) {
        try {
            const params = new URLSearchParams();

            const filterKeys = Object.keys(details);

            filterKeys.forEach(key => {
                const value = details[key];
                if (value !== undefined && value !== null) {
                    params.append(key, value);
                }
            });
            const response = await httpcommon.get(`/LabDashboard/listSlac?${params.toString()}`, {});

            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async generateOtp(payload) {
        try {
            const response = await httpcommon.post(`UserOTP/generateUserOTP/${payload.mobileNumber}/${payload.mobileCheck}`);
            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        }
        catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getUserConsent() {
        try {
            const configurationObject = {
                method: 'GET',
                url: process.env.REACT_APP_CMS_VERSION_URL,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios(configurationObject);
            return response.data;
        } catch (error) {
            console.log('Warning', error);
            return error.response;
        }
    }
    async getApiCMS(id) {
        const NewCmsToken = 'Bearer 6f1797939dbb00599d8896d29f4e114b257e1e252d6856294951bdcdd7be292b7b49d6a266f2319d74e024cac8a99de3ab5256b82e25d4bb931a5c44a28f8d5ddcdffc51ee8abad8f62ac14a985397c4636b7575a9709d1cd6ae4bed4419494c2ea96ed3bc6223a5bac20e6a07342f7f17fb1cc7c87dccd85c0de0930896afe1'
        try {
            const configurationObject = {
                method: 'GET',
                url: process.env.REACT_APP_USER_CMS_URL + `&userId=${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: NewCmsToken,
                },
            };
            const response = await axios(configurationObject);
            return response.data;
        } catch (error) {
            console.log('Warning', error);
            return error.response;
        }
    }
    async userConsentPost(payload) {
        try {
            const configurationObject = {
                method: 'POST',
                url: process.env.REACT_APP_USER_ADDCONSENT,
                data: payload,
            };
            const response = await axios(configurationObject);
            return response.data;
        } catch (error) {
            // console.log('Warning', error);
            // return error.response;
        }
    }
}


const apiService = new ApiService();
export default apiService;

export const checkIsLoggedIn = () => {
    const loginObjStr = localStorage.getItem('loginObj');

    try {
        const loginObj = JSON.parse(loginObjStr);
        const userCode = loginObj?.userSession?.userCode || null;
        return userCode;
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
    }

}

