import React, { useState, useEffect, useCallback } from 'react';
import apiService from '../../services/actions';
import {formatToCurrentDateTime, formatToLocalDateTime } from '../common';

import {
  Paper,
  Tab,
  Tabs,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Divider,
  Box,
  Pagination,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import FilterIcon from '@mui/icons-material/FilterList';
import PrintIcon from '@mui/icons-material/Print';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import PatientSearch from '../common/patientSearch';
import CustomDatePicker from '../common/customdatepicker';
import Search from '@mui/icons-material/Search';
import { formatDateToMMDDYYYY, formateDateToDDMMYYYYHHMM } from '../common';
import ReactToPrint from 'react-to-print';

const Notifications = (props) => {
  const [advancedSearchPage, setAdvancedSearchPage] = useState(1);
  const userObj = JSON.parse(localStorage.getItem('loginObj'))
  const [user, setUser] = useState(userObj.user);
  const [notification, setNotification] = useState([]);
  const [newModal, setnewModal] = useState(false);
  const [patientSearch, setpatientSearch] = useState(false);
  const [mode, setMode] = useState('');
  const [filterModal, setfilterModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const patientDetailsPerPage = 5;
  const tableRef = React.createRef();
  const currentDateTime = formatToCurrentDateTime(new Date());

  const [filterValue, setfilterValue] = useState({
    fromDate: '',
    toDate: '',
    name: ''

  });
  const handlePatientPageChange = (event, value) => {
    setAdvancedSearchPage(value);
  }
  const [content, setnotificationContent] = useState({
    content: '',
    subject: '',
    fromName: '',
    patientName: '',
    email: '',
  });


  const [activeTab, setActiveTab] = useState(2);
  console.log(user)

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    getUserNotification()
  }, [activeTab]);
  const getUserNotification = async () => {
    setAdvancedSearchPage(1)
    let payload
      = {}

    if (activeTab == 2) {
      payload.trigeredTo = user.code

    }
    else {
      payload.trigeredBy = user.code

    }

    if (filterValue.fromDate) {
      payload.fromDate = formatDateToMMDDYYYY(filterValue.fromDate)
    }
    if (filterValue.toDate) {
      payload.toDate = formatDateToMMDDYYYY(filterValue.toDate)
    }
    if (selectedPatient) {
      if (activeTab == 2) {
        payload.trigeredTo = user.code
        payload.trigeredBy = selectedPatient.code
      }
      else {
        payload.trigeredTo = selectedPatient.code
        payload.trigeredBy = user.code
      }
    }
    try {
      const res = await apiService.getNotification(payload);
      if (res.status === 200 && res?.data) {
        setNotification(res?.data)
        setfilterModal(false)
        setfilterValue({})
        setSelectedPatient({})
      } else {
        console.error('API error:', res.statusText);
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  }

  // const getFilterdNotification = async () => {
  //   let payload
  //     = {}
  //   if (filterValue.fromDate != '') {
  //     payload.fromDate = formatDateToMMDDYYYY(filterValue.fromDate)
  //   }
  //   if (filterValue.toDate != '') {
  //     payload.toDate = formatDateToMMDDYYYY(filterValue.toDate)
  //   }
  //   if (activeTab == 2) {
  //     payload.trigeredTo = user.code

  //   }
  //   else {
  //     payload.trigeredBy = user.code

  //   }


  //   try {
  //     const res = await apiService.getNotification(payload);
  //     if (res.status === 200 && res?.data) {
  //       setNotification(res?.data)
  //     } else {
  //       console.error('API error:', res.statusText);
  //     }
  //   } catch (error) {
  //     console.error('API request error:', error);
  //   }
  // }
  const handleClose = () => {
    setnewModal(false)
    setpatientSearch(false)
  }
  const handleCloseps = (e) => {
    setSelectedPatient(e)
    setpatientSearch(false)
  }

  const handlefilterChange = (field, value) => {
    setfilterValue({
      ...filterValue,
      [field]: value,
    })
  }
  const handleInputChange = (field, value) => {
    setnotificationContent({
      ...content,
      [field]: value,
    });
  }
  const openSearchFilter = () => {
    setpatientSearch(true)
  }
  const addNotification = async () => {
    let payload
      = {
      "userCode": user.code,
      "patientName": selectedPatient.name,
      "patientCode": selectedPatient.code,
      "date": currentDateTime,
      "subject": content.subject,
      "content": content.content,
      "status": 1,
      "createdDate": currentDateTime,
      "fromDate": currentDateTime,
      "updatedDate": currentDateTime,
      "createdBy": user.code,
      "updatedBy": user.code,
      "trigeredBy": user.code,
      "trigeredTo": selectedPatient.code,
      "trigeredUsertype": "P",
      "fromName": selectedPatient.name
    }
    try {
      const res = await apiService.postNotification(payload);
      if (res.status === 200 && res?.data) {
        getUserNotification()
        setnewModal(false)
      } else {
        console.error('API error:', res.statusText);
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  }
  const cancelNotification = () => {
    setnewModal(false)
    setfilterModal(false)
  }
  const openDailogs = () => {
    setMode('New')
    setnotificationContent({})
    setnewModal(true)
  }

  const getPatientDataForPage = useCallback((advancedSearchPage) => {
    const startIndex = (advancedSearchPage - 1) * patientDetailsPerPage;
    const endIndex = startIndex + patientDetailsPerPage;
    const dataForPage = notification.slice(startIndex, endIndex);
    return dataForPage;
  }, [patientDetailsPerPage, notification])
  const openFilter = () => {
    setfilterModal(true)
  }
  const handleRowDoubleClick = (rowObject) => {
    // Do something with the entire object of the clicked row
    setMode('View')
    setnewModal(true)
    setnotificationContent(rowObject)
    console.log('Row Double-Clicked:', rowObject);
    // You can perform additional actions or set state based on the clicked row
  };
  const cancelprops = () => {
    props.onClose()
  }
  return (
    <>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        bnotifiRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        overflow: 'auto',
        width: '90%', // Set your desired width
        // height: '55%', // Set your desired height
      }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Notification</h2>
        {/* Add your buttons here */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          {activeTab == 1 && <IconButton color="primary" style={{ marginRight: '10px' }}
            onClick={() => openDailogs()} >
            {/* Add your primary icon (e.g., New) */}
            {/* Replace the 'AddIcon' with the actual Material-UI icon component you want to use */}
            <AddIcon /><span>New</span>
          </IconButton>}

          <IconButton color="primary" style={{ marginRight: '10px' }} onClick={() => openFilter()}>
            {/* Add your primary icon (e.g., Filter) */}
            {/* Replace the 'FilterIcon' with the actual Material-UI icon component you want to use */}
            <FilterIcon /><span>Filter</span>
          </IconButton>
          <IconButton color="secondary">
            {/* Add your secondary icon (e.g., Print) */}
            {/* Replace the 'PrintIcon' with the actual Material-UI icon component you want to use */}
            {/* <PrintIcon  /> */}
            <ReactToPrint
              trigger={() => <IconButton color="secondary"><PrintIcon /></IconButton>}
              content={() => tableRef.current}
            />
          </IconButton>
        </div>
        <Tabs
          value={activeTab}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab label="Received " onClick={() => handleTabClick(2)}
          />
          <Tab label="Sent" onClick={() => handleTabClick(1)}
          />
        </Tabs>

        <div>
          {activeTab === 1 && <div>
            {/* <Table data={data} />   */}
            <TableContainer ref={tableRef} className="print-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Content</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPatientDataForPage(advancedSearchPage).map((notifi) => (
                    <TableRow onDoubleClick={() => handleRowDoubleClick(notifi)}>
                      <TableCell>{notifi.date}</TableCell>

                      <TableCell>{notifi.patientName}</TableCell>
                      <TableCell>{notifi.subject}</TableCell>
                      <TableCell>{notifi.content}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          {activeTab === 2 &&
            <TableContainer ref={tableRef}>
              <Table>
                <TableHead>
                  <TableRow  >
                    <TableCell>Date</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Content</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPatientDataForPage(advancedSearchPage).map((notifi) => (
                    <TableRow onDoubleClick={() => handleRowDoubleClick(notifi)}>
                      <TableCell>{notifi.date}</TableCell>

                      <TableCell>{notifi.patientName}</TableCell>
                      <TableCell>{notifi.subject}</TableCell>
                      <TableCell>{notifi.content}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          }

        </div>
        {notification?.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <Pagination
              count={Math.ceil(notification.length / notification)}
              page={advancedSearchPage}
              color="secondary"
              onChange={handlePatientPageChange}
            />
          </Box>
        )}
        <Grid style={{ direction: "rtl", marginTop: "10px" }}>
          <Button variant="contained" color="primary"
            onClick={cancelprops}
          >
            Cancel
          </Button>
        </Grid>
      </div>
      <Modal open={newModal}
        onClose={handleClose} className='customModal'>
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Paper elevation={3} style={{ width: '80%', maxWidth: '600px', padding: '16px', marginBottom: '16px', overflowX: 'auto', maxHeight: '80vh' }}>
              <h4 style={{ backgroundColor: "rgb(245, 245, 245)", margin: "-16px -15px 16px", padding: "6px", color: "#black" }}>{mode == 'New' ? 'Add New' : 'View'}</h4>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>

                  <TextField
                    label="From"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={user.firstName}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    label="To"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={selectedPatient?.name}

                    onChange={(e) => handleInputChange('patientName', e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={openSearchFilter} >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }} />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    label="Subject"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={content.subject}

                    onChange={(e) => handleInputChange('subject', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4} // Set the number of rows you want to display
                    size='small'
                    value={content.content}
                    onChange={(e) => handleInputChange('content', e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid style={{ direction: "rtl", marginTop: "10px" }}>
                <Button variant="contained" color="primary"
                  //  onClick={handleAdvancedSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}
                  sx={{ marginLeft: "10px" }}
                  onClick={addNotification}
                >
                  Sent
                </Button>
                <Button variant="contained" color="primary"
                  onClick={cancelNotification}
                >
                  Cancel
                </Button>
              </Grid>
            </Paper>            </div>
        </>
      </Modal>
      <Modal open={patientSearch}
      >
        <PatientSearch onClose={handleCloseps} />
      </Modal>
      <Modal open={filterModal}
        onClose={handleClose} className='customModal'>
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Paper elevation={3} style={{ width: '80%', maxWidth: '600px', padding: '16px', marginBottom: '16px', overflowX: 'auto', maxHeight: '80vh' }}>
              <h4 style={{ backgroundColor: "rgb(245, 245, 245)", margin: "-16px -15px 16px", padding: "6px", color: "#black" }}>Filter</h4>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>


                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Patient"
                      variant="outlined"
                      fullWidth
                      size='small'
                      value={selectedPatient?.name}

                      onChange={(e) => handleInputChange('patientName', e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" onClick={openSearchFilter} >
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomDatePicker
                      label="From Date"
                      value={filterValue?.fromDate}
                      onChange={(date) => handlefilterChange('fromDate', date)}
                    />

                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomDatePicker
                      label="To Date"
                      value={filterValue?.toDate}
                      onChange={(date) => handlefilterChange('toDate', date)}
                    />

                  </Grid>
                </Grid>

              </Grid>

              <Grid style={{ direction: "rtl", marginTop: "10px" }}>
                <Button variant="contained" color="primary"
                  //  onClick={handleAdvancedSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}
                  sx={{ marginLeft: "10px" }}
                  onClick={getUserNotification}
                >
                  Search
                </Button>
                <Button variant="contained" color="primary"
                  onClick={cancelNotification}
                >
                  Cancel
                </Button>
              </Grid>
            </Paper>            </div>
        </>
      </Modal>
    </>
  );
};

export default Notifications;
