import React, { useCallback, useEffect, useState } from 'react';
import {
    Paper,
    Tab,
    Tabs,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Modal,
    Card,
    CardHeader,
    IconButton,
    CardContent,
    Divider,
    Box,
    Pagination,
    CircularProgress,
} from '@mui/material';
import CustomDatePicker from '../common/customdatepicker';
import dayjs from 'dayjs';
import apiService from '../../services/actions';
import uploadIcon from "../../assets/images/uploadIcon.svg";
import eyeIcon from "../../assets/images/eyeIcon.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import CloseIcon from '@mui/icons-material/Close';
import { formatDateToMMDDYYYY } from '../common';

const Orders = () => {
    const [tabValue, setTabValue] = useState(0);
    const [hospitalId, setHospitalId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const itemsPerPage = 6;
    const [editMode, setEditMode] = useState(null);

    const toggleEditMode = (orderId) => {
        setEditMode((prevMode) => (prevMode === orderId ? null : orderId));
    };

    const handleUploadClick = (order) => {
        setSelectedOrder(order);
        setModalOpen(true);
    };

    const parseLocationData = (locationData) => {
        try {
            const data = JSON.parse(locationData);
            const { hospitalId, locationId } = data;
            setHospitalId(hospitalId);
            setLocationId(locationId);
        } catch (error) {
            console.error('Error parsing location data:', error);
        }
    };

    useEffect(() => {
        const locationData = localStorage.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            parseLocationData(locationData);
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
    }, []);

    const [searchFilters, setSearchFilters] = useState({
        orderId: '',
        patientId: '',
        fromDate: dayjs().subtract(7, 'day'),
        toDate: dayjs(),
        procedureStatus: -2,
        uploadStatus: 2,
    });

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setOrderDetails([]);
        setPage(1)
        if (newValue === 1) {
            setSearchFilters(prevFilters => ({
                ...prevFilters,
                uploadStatus: 1
            }));
        }
        else {
            setSearchFilters(prevFilters => ({
                ...prevFilters,
                uploadStatus: 2
            }));
        }
    };

    const handleSearch = async () => {
        setLoading(true);
        const payload = {
            procedureStatus: searchFilters?.procedureStatus,
            fromDate1: searchFilters?.fromDate ? formatDateToMMDDYYYY(searchFilters?.fromDate) : undefined,
            toDate1: searchFilters?.toDate ? formatDateToMMDDYYYY(searchFilters?.toDate) : undefined,
            orderId: searchFilters?.orderId ? searchFilters.orderId : undefined,
            patientId: searchFilters?.patientId ? searchFilters.patientId : undefined,
            hospitalId: hospitalId ? hospitalId : undefined,
            locationId: locationId ? locationId : undefined,
            status: searchFilters.uploadStatus,
        };
        try {
            const ordersDetailsResponse = await apiService.getOrderDetails(payload);
            setLoading(false);
            if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                setOrderDetails(ordersDetailsResponse.data)
            } else {
                console.error('API error:', ordersDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };

    const getDataForPage = useCallback((page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const dataForPage = orderDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [orderDetails, itemsPerPage]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        getDataForPage(page);
    }, [page, getDataForPage]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const ProcedureStatusDropdown = ({ currentStatus, orderId, updateOrderStatus }) => {
        const [status, setStatus] = useState(currentStatus);

        const handleChange = (event) => {
            const newStatus = event.target.value;
            setStatus(newStatus);
            updateOrderStatus(orderId, newStatus);
        };

        return (
            <Select variant="standard" value={status} onChange={handleChange} size="small">
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Completed</MenuItem>
                <MenuItem value={4}>Cancelled</MenuItem>
            </Select>
        );
    };

    const handleStatusChange = (orderId, newStatus) => {
        // Make an API call to update the order status
        // Pass the 'newStatus' value and 'orderId'
        // Example:
        // updateOrderStatus(orderId, newStatus);
    };

    const handleStatusDropdownChange = (orderId, newStatus) => {
        handleStatusChange(orderId, newStatus);
        setEditMode(null); // Exit the edit mode after selecting a new status
    };

    const updateOrderStatus = (orderId, newStatus) => {
        console.log(orderId, newStatus)
        // Make an API call to update the order status here
        // Example:
        // apiService.updateOrderStatus(orderId, newStatus)
        //   .then((response) => {
        //       // Handle success
        //   })
        //   .catch((error) => {
        //       // Handle error
        //   });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', marginBottom: '1rem' }}>
                <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: '600' }}>
                    All Orders
                </Typography>
            </div>
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="secondary" />
            </div>
            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            label="Order ID"
                            variant="outlined"
                            fullWidth
                            value={searchFilters.orderId}
                            size='small'
                            onChange={(e) => setSearchFilters({ ...searchFilters, orderId: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Patient ID"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={searchFilters.patientId}
                            onChange={(e) => setSearchFilters({ ...searchFilters, patientId: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CustomDatePicker
                            label="From Date"
                            value={searchFilters.fromDate}
                            onChange={(date) => setSearchFilters({ ...searchFilters, fromDate: date })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CustomDatePicker
                            label="To Date"
                            value={searchFilters.toDate}
                            onChange={(date) => setSearchFilters({ ...searchFilters, toDate: date })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel size='small' >Procedure Status</InputLabel>
                            <Select
                                label="Procedure Status"
                                value={searchFilters.procedureStatus}
                                onChange={(e) => setSearchFilters({ ...searchFilters, procedureStatus: e.target.value })}
                                size='small'
                            >
                                <MenuItem value={-2}>All</MenuItem>
                                <MenuItem value={1}>Pending</MenuItem>
                                <MenuItem value={2}>Completed</MenuItem>
                                <MenuItem value={4}>Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel size='small' >Upload Status</InputLabel>
                            <Select
                                label="Upload Status"
                                value={searchFilters.uploadStatus}
                                size='small'
                                onChange={(e) => setSearchFilters({ ...searchFilters, uploadStatus: e.target.value })}
                            >
                                <MenuItem value={0}>Cancelled</MenuItem>
                                <MenuItem value={1}>Pending</MenuItem>
                                <MenuItem value={2}>Uploaded</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Button variant="contained" color="primary" onClick={handleSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                        Search
                    </Button>
                </Grid>
            </Paper>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
            >
                <Tab label="Completed Orders " />
                <Tab label="Pending Orders" />
            </Tabs>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Patient</TableCell>
                            <TableCell>Diagnostic Name</TableCell>
                            <TableCell>Revenue Source</TableCell>
                            <TableCell>eClinic Name</TableCell>
                            <TableCell>eClinic Location</TableCell>
                            <TableCell>Sample Collection Status</TableCell>
                            <TableCell>Procedure Status</TableCell>
                            <TableCell>Report Upload</TableCell>
                            <TableCell>Referred By</TableCell>
                            <TableCell>Total Amount(INR)</TableCell>
                            <TableCell>Lab Reports Upload</TableCell>
                            <TableCell>Receipt</TableCell>
                            <TableCell>Upload Invoice</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getDataForPage(page).map((order) => (
                            <TableRow key={order.orderId}>
                                <TableCell>{order.orderId}</TableCell>
                                <TableCell>{order.patientName}</TableCell>
                                <TableCell>{order.hospitalName}</TableCell>
                                <TableCell>{order.sourceChannel}</TableCell>
                                <TableCell>{order.chehospitalName}</TableCell>
                                <TableCell>{order.chelocationName}</TableCell>
                                <TableCell>{order.sampleCollectionStatus}</TableCell>
                                <TableCell>
                                    {editMode === order.orderId ? (
                                        <ProcedureStatusDropdown
                                            currentStatus={order.procedureStatus}
                                            orderId={order.orderId}
                                            updateOrderStatus={updateOrderStatus}
                                        />
                                    ) : (
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => toggleEditMode(order.orderId)}
                                        >
                                            {order.procedureStatus === 2
                                                ? 'Completed'
                                                : order.procedureStatus === 1
                                                    ? 'Pending'
                                                    : order.procedureStatus === 4
                                                        ? 'Cancelled'
                                                        : order.procedureStatus}
                                        </span>
                                    )}
                                </TableCell>
                                <TableCell>{order.reportUpload}</TableCell>
                                <TableCell>{order.referredBy}</TableCell>
                                <TableCell>{order.paidAmount}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleUploadClick(order)}>
                                        <img src={uploadIcon} alt="Upload" />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleUploadClick(order)}>
                                        <img src={eyeIcon} alt="View" />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex' }}>
                                        <Button onClick={() => handleUploadClick(order)}>
                                            <img src={uploadIcon} alt="Upload" />
                                        </Button>
                                        <Button onClick={() => handleUploadClick(order)}>
                                            <img src={eyeIcon} alt="View" />
                                        </Button>
                                    </div>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {orderDetails?.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <Pagination
                        count={Math.ceil(orderDetails.length / itemsPerPage)}
                        page={page}
                        color="secondary"
                        onChange={handlePageChange}
                    />
                </Box>
            )}

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ width: '120vh', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Upload Report</Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Patient Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            Rajesh
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Mobile Number:
                                        </Typography>
                                        <Typography variant="body1">
                                            7022102144
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Order ID:
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.orderId}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Requested Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            23/10/2023 17:00
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Confirmed Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            23/10/2023 17:00
                                            {/* {selectedOrder?.confirmedDateTime} */}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Home Sample Collection Address
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.address}
                                        </Typography>
                                    </div>
                                </div>
                                <Button variant="contained" color="primary">
                                    Upload Report
                                </Button>
                            </div>
                            <div>
                                <Typography variant="h6" sx={{ marginBottom: '1rem', fontSize: '16px', fontWeight: 500 }}>Check Test Details</Typography>
                                <Grid container>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Lab Tests</TableCell>
                                                    <TableCell>File Type</TableCell>
                                                    <TableCell>View</TableCell>
                                                    <TableCell>Download</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderDetails.map((rec) => (
                                                    <TableRow>
                                                        <TableCell>Blood Test</TableCell>
                                                        <TableCell>PDF</TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleUploadClick(rec)}>
                                                                <img src={eyeIcon} alt="View" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleUploadClick(rec)}>
                                                                <img src={downloadIcon} alt="Download" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleUploadClick(rec)}>
                                                                <img src={deleteIcon} alt="Delete" />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default Orders;
