import React, { useState, useEffect, useCallback } from 'react';
import {
    TextField,
    Button,
    Grid,
    Card,
    CardContent,
    CardActions,
    IconButton,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CardHeader,
    Collapse,
    Typography,
    Box,
    Pagination,
    CircularProgress,
    Modal,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    FormControlLabel,
    Autocomplete,

} from '@mui/material';
import CustomDatePicker from '../common/customdatepicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import apiService from '../../services/actions';
import CustomDateTimePicker from '../common/customdatetimepicker';
import { formatDateToMMDDYYYY, formateDateToDDMMYYYYHHMM } from '../common';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import moment from 'moment';

const ReceivedOrder = () => {
    console.log(localStorage?.userRole)
    const [filters, setFilters] = useState({
        fromDate: dayjs().subtract(7, 'day'),
        toDate: dayjs(),
        status: 1,
    });
    const [orderDetails, setOrderDetails] = useState([]);
    const [expanded, setExpanded] = useState(-1);
    const [hospitalId, setHospitalId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [loading, setLoading] = useState(false);
    const [patientLoading, setPatientLoading] = useState(false);
    const [advancedFiltersModal, setAdvancedFiltersModal] = useState(false);
    const [patientDetails, setPatientDetails] = useState([]);
    const [page, setPage] = useState(1);
    const [advancedSearchPage, setAdvancedSearchPage] = useState(1);
    const itemsPerPage = 3;
    const patientDetailsPerPage = 5;
    const [advancedFiters, setAdvancedFilters] = useState({
        parentId: '',
        patientFirstName: '',
        patientLastName: '',
        mobileNumber: '',
        email: '',
        dateOfBirth: null
    });
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [activeTest, setactiveTest] = useState();
    const [formData, setFormData] = useState([
        {
            homeSampleCollectionDateTime: '',
            labTestDateTime: '',
            techniciansAssigned: '',
            contactNumber: '',
            status: '',
        }
    ]);
    const [labList, setLablist] = useState([]);

    const handleFieldChange = (recordIndex, field, value) => {
        setFormData((prevData) => {
            const newData = [...prevData];
            if (field === 'contactNumber') {
                const numericValue = value.replace(/\D/g, '');
                const maxLength = 10;
                newData[recordIndex] = {
                    ...newData[recordIndex],
                    [field]: numericValue.slice(0, maxLength),
                };
            } else {
                newData[recordIndex] = {
                    ...newData[recordIndex],
                    [field]: value,
                };
            }

            return newData;
        });
    };

    const parseLocationData = (locationData) => {
        try {
            const data = JSON.parse(locationData);
            const { hospitalId, locationId } = data;
            setHospitalId(hospitalId);
            setLocationId(locationId);
        } catch (error) {
            console.error('Error parsing location data:', error);
        }
    };

    useEffect(() => {
        const locationData = localStorage.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            parseLocationData(locationData);
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleExpandClick = (index, data) => {
        setactiveTest(data)
        console.log(data)
        setExpanded(expanded === index ? -1 : index);
        let payload = {
            homeSampleCollectionDateTime: data.preferDateTime ? data.preferDateTime : '',
            labTestDateTime: data.collectionSampleDate ? data.collectionSampleDate : '',
        }
        updateObjectAtIndex(index, payload)
    };
    const updateObjectAtIndex = (index, updatedObject) => {
        setFormData((prevArray) => {
            // Create a copy of the array to avoid mutating state directly
            const newArray = [...prevArray];
            newArray[index] = updatedObject;
            return newArray;
        });
    };
const handleInputChange = (field, value) => {
    setAdvancedFilters({
        ...advancedFiters,
        [field]: value,
    });
};
    const getLabList=async ()=>{
        const res1 = await apiService.getLabList();
        console.log(res1)
        setLablist(res1.data)

    }
    const handleSearch = async () => {
        setLoading(true)
        let hospitalIdlocalCopy = null;
        let locationIdlocalCopy = null;
        const locationData = localStorage?.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            try {
                const data = JSON.parse(locationData);
                const { hospitalId, locationId } = data;
                setHospitalId(hospitalId);
                setLocationId(locationId);
                hospitalIdlocalCopy = hospitalId;
                locationIdlocalCopy = locationId;
            } catch (error) {
                console.error('Error parsing location data:', error);
            }
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
        const payload = {
            procedureStatus: filters?.status === 1 ? 0 : filters?.status,
            fromDate1: filters?.fromDate ? formatDateToMMDDYYYY(filters?.fromDate) : undefined,
            toDate1: filters?.toDate ? formatDateToMMDDYYYY(filters?.toDate) : undefined,
            hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
            locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
            status: filters?.status,
            patientId: selectedPatient ? selectedPatient?.code : undefined,
            orderDetailsRequired: 'Y',
            labName:filters?.hospitalName ? filters.hospitalName : undefined,



        };
        try {
            const ordersDetailsResponse = await apiService.getOrderDetails(payload);
            setLoading(false)
            if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                setOrderDetails(ordersDetailsResponse.data)
            } else {
                console.error('API error:', ordersDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };

    useEffect(() => {
        handleSearch();
        getLabList()
    }, []);

    const getDataForPage = useCallback((page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const dataForPage = orderDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [orderDetails, itemsPerPage]);

    const getPatientDataForPage = useCallback((advancedSearchPage) => {
        const startIndex = (advancedSearchPage - 1) * patientDetailsPerPage;
        const endIndex = startIndex + patientDetailsPerPage;
        const dataForPage = patientDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [patientDetailsPerPage, patientDetails])

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePatientPageChange = (event, value) => {
        setAdvancedSearchPage(value);
    }
    useEffect(() => {
        getDataForPage(page);
    }, [page, getDataForPage]);

    useEffect(() => {
        getPatientDataForPage(advancedSearchPage);
    }, [advancedSearchPage, getPatientDataForPage]);

    const handleCloseAdancedFilterModal = () => {
        setAdvancedFiltersModal(false);
        setSelectedPatient(null);
    };

    const onCancelAdvancedSearch = () => {
        setAdvancedFiltersModal(false);
        setSelectedPatient(null);
    };

    const onApplyClick = () => {
        setAdvancedFiltersModal(false);
        handleSearch()
    };

    const handleAdvancedSearch = async (e) => {
        setPatientLoading(true)
        let payload = {
            firstName: advancedFiters?.firstName ? advancedFiters?.firstName : undefined,
            lastName: advancedFiters?.lastName ? advancedFiters.lastName : undefined,
            mobile: advancedFiters?.mobile ? advancedFiters.mobile : undefined,
            email: advancedFiters?.email ? advancedFiters.email : undefined,
            dob: advancedFiters?.dob ? formatDateToMMDDYYYY(advancedFiters?.dob ): undefined,


        };
        if (e == 'P') {
            payload.code = advancedFiters?.code ? advancedFiters.code : undefined
            payload.status = 1

        }
        else {
            payload.parentCode = advancedFiters?.code ? advancedFiters.code : undefined
        }
        try {
            const patientDetailsResponse = await apiService.getPatientDetails(payload);
            setPatientLoading(false)
            if (patientDetailsResponse.status === 200 && patientDetailsResponse?.data) {
                setPatientDetails(patientDetailsResponse.data)
            } else {
                console.error('API error:', patientDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };

    const handleRowClick = (event, row) => {
        event.stopPropagation();
        setSelectedPatient((prevSelected) => {
            if (prevSelected === row) {
                return null;
            } else {
                return row;
            }
        });
    };

    const confirmOrder = async (orderDetails) => {
        setLoading(true);
        try {
            const recordIndex = getDataForPage(page).findIndex((record) => record.orderId === orderDetails.orderId);
            if (!formData[recordIndex]?.status) {
                setLoading(false);

                return toast.error("Please select the Status")
            }
            if (recordIndex !== -1) {
                const updatedOrderDetails = {
                    ...orderDetails,
                    procedureStatus: 1,
                    technicianAssigned: formData[recordIndex]?.techniciansAssigned,
                    technicianContactNo: formData[recordIndex]?.contactNumber,
                    confirmDateTime: moment(formData[recordIndex]?.confirmDateTime)?.format("YYYY-MM-DD HH:mm:ss"),
                    collectionSampleDate: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    preferDateTime: formData[recordIndex]?.labTestDateTime ? moment(formData[recordIndex]?.labTestDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.collectionSampleDate ? moment(activeTest.collectionSampleDate)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    testLabDateTime: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    testLabDateTime1: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    collectionStatus: formData[recordIndex]?.status,
                };
                const confirmOrderResponse = await apiService.patientLabTestsOrder(updatedOrderDetails, 'PUT');
                setLoading(false);

                if (confirmOrderResponse?.status === 200 && confirmOrderResponse?.data) {
                    if (confirmOrderResponse.data) {
                        toast.success("Order updated successfully.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        setOrderDetails([]);
                        handleSearch();
                    } else {
                        toast.error("Please try after sometime", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            } else {
                setLoading(false);
                toast.error("Order details not found.", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error confirming order:", error);
        }
    };



    return (
        <>
            <div className="flex flex-col w-full mt-12">
                <div className="flex justify-between mb-4">
                    <Typography variant="h6" className="font-semibold">
                        Received Orders
                    </Typography>
                    <div className="text-right">
                        <Button variant="contained" onClick={() => setAdvancedFiltersModal(true)} className="rounded-full">
                            +Advanced Filter
                        </Button>
                    </div>
                </div>
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center" style={{ display: loading ? 'flex' : 'none' }}>
                    <CircularProgress color="secondary" />
                </div>
                <Paper elevation={3} className="p-4 mb-4">
                    <Grid container spacing={2}>
                        {/* Grid items with Tailwind classes */}
                        <Grid item xs={12} md={6} lg={3} className="mb-4">
                            <FormControl fullWidth variant="outlined">
                                <InputLabel size="small">Status</InputLabel>
                                <Select
                                    name="status"
                                    label="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    size="small"
                                >
                                    <MenuItem value={1}>Not Confirmed</MenuItem>
                                    <MenuItem value={2}>Confirmed</MenuItem>
                                    <MenuItem value={5}>Cancelled By Patient</MenuItem>
                                    <MenuItem value={6}>Cancelled By Diagnostic</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CustomDatePicker
                                label="From Date"
                                value={filters.fromDate}
                                onChange={(date) => setFilters({ ...filters, fromDate: date })}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CustomDatePicker
                                label="To Date"
                                value={filters.toDate}
                                onChange={(date) => setFilters({ ...filters, toDate: date })}
                            />
                        </Grid>
                      <Grid item xs={2} className='flex gap-3'>
                      { localStorage?.userRole == 'DIAGGROUPADMIN' && (    <FormControl fullWidth variant="outlined">
                            <Autocomplete
          options={labList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Lab"
              variant="outlined"
              size="small"
              placeholder="Filter labs"
            />
          )}
              onChange={(event, newValue) => setFilters({ ...filters, hospitalName: newValue?.name || '' })}

          value={labList.find((lab) => lab.name === filters.hospitalName) || null}
        />
                        </FormControl>)}
                        <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                className="mt-4 md:mt-0"
                            >
                                Search
                            </Button>
                    </Grid>  
            
                    </Grid>
                </Paper>

                <div className="flex flex-col w-full">
                    {getDataForPage(page).map((data, index) => (
                        <Card key={index} className="mb-4">
                            <CardHeader
                                title={
                                    <div className="w-full flex justify-between">
                                        <div>{`${data?.patientSalutation ? data?.patientSalutation + ' ' : ''}${data?.patientName}`}</div>
                                        <div>{`Patient Id: ${data?.patientId}`}</div>
                                    </div>
                                }
                                avatar={
                                    <div className="w-10 h-10 border-1 border-gray-300 flex items-center justify-center mt-2 mr-2 bg-white">
                                        <Typography variant="body1" className="text-blue-500">
                                            {data?.patientName ? data?.patientName.charAt(0).toUpperCase() : 'U'}
                                        </Typography>
                                    </div>
                                }
                            />
                            <CardContent className="flex">
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Order Id</span>: <span>{data?.orderId}</span>
                                        </Typography>
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Diagnostic Name</span>: <span>{data?.hospitalName}</span>
                                        </Typography>
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">eClinic Name</span>: <span>{data?.chehospitalName}</span>
                                        </Typography>

                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">eClinic Location</span>: <span>{data?.chelocationName}</span>
                                        </Typography>

                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Requested Date & Time</span>: <span>{formateDateToDDMMYYYYHHMM(data?.collectionSampleDate)}</span>
                                        </Typography>

                                        <Typography className="pr-8" variant="body1">
                                            <span className="font-semibold">Mobile</span>: <span>{data?.patientMobile}</span>
                                        </Typography>
                                    </div>

                                    <div className="flex flex-row mt-6">
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Address</span>: <span>{data?.deliveryFullAddress}</span>
                                        </Typography>
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Lab ConfirmId</span>: <span>{data?.extLabConfirmId}</span>
                                        </Typography>
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">LabConfirmID Status</span>: <span>{data?.extLabConfirmIdStatus}</span>
                                        </Typography>
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Lab Test Name</span>:
                                            <span>
                                                {' '}
                                                {data?.patientLabTestsOrderDetailsList?.map((labTest, index) => (
                                                    <React.Fragment key={index}>
                                                        {labTest.labTestName}
                                                        {index < data.patientLabTestsOrderDetailsList.length - 1 && ', '}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </Typography>

                                        <Typography variant="body1">
                                            <span style={{ fontWeight: 600 }}>Sample Collection Address</span>: <span style={{ fontWeight: 400 }}>{data?.homeSamplePatientAddress}</span>
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>

                            <CardContent>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                    <Typography variant="body2" color="textSecondary" component="div">
                                        <Typography paragraph>Enter Details</Typography>
                                    </Typography>
                                    <CardActions disableSpacing>
                                        <IconButton
                                            aria-expanded={expanded === index}
                                            onClick={() => handleExpandClick(index, data)}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                </div>
                            </CardContent>

                            <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                <CardContent style={{
                                    marginTop: '-2rem'
                                }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={3}>
                                            <CustomDateTimePicker
                                                variant="outlined"
                                                fullWidth
                                                label="Sample Collection Date & Time"
                                                selectedDateTime={formData[index]?.homeSampleCollectionDateTime}
                                                defaultDateTime={data?.preferDateTime ? dayjs(data?.preferDateTime) : null}
                                                onChange={(dateTime) =>
                                                    handleFieldChange(index, 'homeSampleCollectionDateTime', dateTime)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CustomDateTimePicker
                                                variant="outlined"
                                                fullWidth
                                                label="Test at Lab Date & Time"
                                                selectedDateTime={formData[index]?.labTestDateTime}
                                                defaultDateTime={data?.collectionSampleDate ? dayjs(data?.collectionSampleDate) : null}
                                                onChange={(dateTime) =>
                                                    handleFieldChange(index, 'labTestDateTime', dateTime)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                label="Technicians Assigned"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={formData[index]?.techniciansAssigned || ''}
                                                onChange={(e) => handleFieldChange(index, 'techniciansAssigned', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                label="Contact Number"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                type="text"
                                                value={formData[index]?.contactNumber || ''}
                                                onChange={(e) => handleFieldChange(index, 'contactNumber', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel size="small" >Status</InputLabel>
                                                <Select
                                                    label="Status"
                                                    size="small"
                                                    value={formData[index]?.status !== undefined ? formData[index]?.status : ''}
                                                    onChange={(e) => handleFieldChange(index, 'status', e.target.value)}
                                                >
                                                    <MenuItem value="1">Order Confirmed</MenuItem>
                                                    <MenuItem value="3">Technician Assigned</MenuItem>
                                                    <MenuItem value="4">Sample Collected</MenuItem>
                                                    <MenuItem value="5">Cancelled by Patient</MenuItem>
                                                    <MenuItem value="6">Cancelled by Diagnostic</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Button variant="contained" color="primary" style={{ margin: '1rem' }} onClick={() => confirmOrder(data)}>
                                            OK
                                        </Button>
                                    </Grid>
                                </CardContent>
                            </Collapse>

                        </Card>
                    ))}
                    {orderDetails?.length > 0 && (
                        <Box className="flex justify-center mt-8">
                            <Pagination
                                count={Math.ceil(orderDetails.length / itemsPerPage)}
                                page={page}
                                color="secondary"
                                onChange={handlePageChange}
                            />
                        </Box>
                    )}
                    {orderDetails?.length === 0 && selectedPatient !== null && (
                        <Box className="flex justify-center mt-8">
                            Unfortunately, we couldn't find any records for the specified patient.
                        </Box>
                    )}
                </div>
            </div>
            <Modal open={advancedFiltersModal} onClose={handleCloseAdancedFilterModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflowY: 'auto' }}>
                    <Card style={{ width: '180vh', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Patient Search</Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseAdancedFilterModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                                overflow: 'auto'
                            }}
                        />
                        <Divider />
                        <CardContent style={{ maxHeight: '50rem', overflowY: 'auto', position: 'relative' }}>
                            {patientLoading && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        zIndex: 1,
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px', overflowX: 'auto' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Patient Id"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.code}
                                            onChange={(e) => handleInputChange('code', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Patient First Name"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.firstName}
                                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Patient Last Name"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.lastName}
                                            onChange={(e) => handleInputChange('lastName', e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginTop: "0.5rem" }}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Mobile Number"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.mobile}
                                            onChange={(e) => handleInputChange('mobile', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={advancedFiters.email}
                                            onChange={(e) => handleInputChange('email', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CustomDatePicker
                                            label="Date of Birth"
                                            value={advancedFiters.dob}
                                            onChange={(date) => handleInputChange('dob', date)}
                                        />
                                        <FormControlLabel sx={{ marginLeft: '1rem' }}
                                            control={
                                                <Checkbox
                                                    value={advancedFiters.status}
                                                    onChange={(e) => handleInputChange('status', e.target.checked)}
                                                />
                                            }
                                            label="Include Inactive"
                                        />
                                    </Grid>
                                </Grid>
                                <Button variant="contained" color="primary" onClick={() => handleAdvancedSearch('P')} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                    Search
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => handleAdvancedSearch('F')} sx={{ borderRadius: '1rem', width: '15rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                    Search Family Member
                                </Button>
                            </Paper>
                            <div>
                                <Grid container>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Patient Id</TableCell>
                                                    <TableCell>First Name</TableCell>
                                                    <TableCell>Last Name</TableCell>
                                                    <TableCell>Gender</TableCell>
                                                    <TableCell>DOB</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Mobile</TableCell>
                                                    <TableCell>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {getPatientDataForPage(advancedSearchPage).map((rec, index) => (
                                                    <TableRow
                                                        key={index}
                                                        onClick={(e) => handleRowClick(e, rec)}
                                                        selected={selectedPatient === rec}
                                                    >
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={selectedPatient === rec}
                                                                onChange={() => { }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{rec?.code}</TableCell>
                                                        <TableCell>{rec?.firstName}</TableCell>
                                                        <TableCell>{rec?.lastName}</TableCell>
                                                        <TableCell>{rec?.gender === 'M' ? 'Male' : 'Female'}</TableCell>
                                                        <TableCell>{rec?.dob}</TableCell>
                                                        <TableCell>{rec?.email}</TableCell>
                                                        <TableCell>{rec?.mobile}</TableCell>
                                                        <TableCell>{rec?.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </div>
                            {patientDetails?.length > 0 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                                    <Pagination
                                        count={Math.ceil(patientDetails.length / patientDetailsPerPage)}
                                        page={advancedSearchPage}
                                        color="secondary"
                                        onChange={handlePatientPageChange}
                                    />
                                </Box>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', flexShrink: 0 }}>
                                <Button style={{ marginLeft: '1rem' }} onClick={onCancelAdvancedSearch}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={onApplyClick}>
                                    Apply
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
        </>
    );
};

export default ReceivedOrder;
