import React, { useEffect, useState } from 'react';
import loginImage from '../../assets/images/loginImage.svg';
import loginDesign from '../../assets/images/loginDesign.svg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import apiService from '../../services/actions';
import { APP_ROUTES } from '../../routes/approutes';
import { toast } from 'react-toastify';
import moment from 'moment'
import {
    Typography,
    Modal,
    CardHeader,
    IconButton,
    Divider,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const LoginPage = () => {
    const navigate = useNavigate();
    let role =''
    const [loginType, setLoginType] = useState('email');
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState
        ({
            userCode: '',
            passWord: '',
            userMobileNumber: '',
            userOTP: ''
        });
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [showSubmitOtp, setShowSubmitOtp] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [resendDisabled, setResendDisabled] = useState(false);
    const intervalRef = React.createRef(null);
    const inputRefs = Array.from({ length: 6 }, () => React.createRef());
    const [forget, setForget] = useState('');

    const [termsView, setTermsView] = useState(false);
    const [privacyView, setPrivacyView] = useState(false);
    const [termsAccept, setTermsAccept] = useState(false);
    const [policyAccept, setPolicyAccept] = useState(false);
    const [SPdetails, setSPDetails] = useState();
    const [consentVersionT, setConsentVersionT] = useState();
    const [consentVersionP, setConsentVersionP] = useState();
    const [showConsentView, setShowConsentView] = useState(false);
    const [versionTextChange, setVersionText] = useState(false);
    useEffect(() => {
        apiService.getUserConsent().then((result) => {
            const policy = result.data.filter(val => val.constentName === "PrivacyPolicy")
            setConsentVersionP(policy[0])
            const terms = result.data.filter(val => val.constentName === "TermsAndCondition")
            setConsentVersionT(terms[0])
        })
    }, [])
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const startTimer = () => {
        setResendDisabled(true);
        setSeconds(60);

        intervalRef.current = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds === 1) {
                    clearInterval(intervalRef.current);
                    setResendDisabled(false);
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };

    const resetTimer = () => {
        clearInterval(intervalRef.current);
        setResendDisabled(false);
        setSeconds(60);
    };

    const handleResendOTP = async () => {
        const payload = {
            "mobileNumber": formData?.userMobileNumber,
            "mobileCheck": 2
        };

        try {
            const otpResponse = await apiService.generateOtp(payload);

            if (otpResponse.status === 200 && otpResponse?.data === 1) {
                setShowOtp(true);
                toast.success('Your OTP has been sent Successfully', {
                    position: toast.POSITION.TOP_CENTER,
                })
                setShowSubmitOtp(true);
            }
            else if (otpResponse.status === 200 && otpResponse?.data?.details) {
                toast.error(otpResponse?.data?.details?.[0], {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error('Please try after sometime', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            console.error('API request error:', error);
        }
        startTimer();
    };

    useEffect(() => {
        if (showOtp) {
            startTimer();
        } else {
            resetTimer();
        }
        return () => clearInterval(intervalRef.current);
    }, [showOtp]);

    const handleOTPChange = (index, value) => {
        const updatedFormData = { ...formData };
        updatedFormData[`otpDigit${index + 1}`] = value;
        setFormData(updatedFormData);

        if (value && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace') {
            inputRefs[index].current.value = '';

            if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        } else if (e.key === 'ArrowLeft') {
            if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        } else if (e.key === 'ArrowRight') {
            if (index < inputRefs.length - 1) {
                inputRefs[index + 1].current.focus();
            }
        }
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const handleSubmitClick = () => {
        onSubmitOTP();
    };

    const handleSubmit = async (e) => {
        console.log(formData)
        if (formData.userCode && formData.passWord && formData.userCode.includes('@curebay.com')) {
            try {
                const tokenResponse1 = await apiService.adloginApi(formData);
                console.log(tokenResponse1)
                if (tokenResponse1.data.message && tokenResponse1.data.userid != '200') {
                    localStorage.clear();

                    return toast.error(tokenResponse1.data.message);

                }
                else {
                    let payload = {
                        "userCode": formData.userCode,
                        "roleCode": "DIAG",
                        "channel": "W"
                    }
                    // const loginResponse1 = await apiService.logincbayuser(JSON.stringify(payload));
                    try {
                        const tokenResponse = await apiService.getToken(formData);

                        if (tokenResponse.status === 200 && tokenResponse?.data) {
                            const access_token = tokenResponse.data.access_token;
                            localStorage.clear();
                            localStorage.setItem('access_token', access_token);
                            localStorage.setItem('access_name', formData.userCode.toUpperCase());
                            localStorage.setItem('refresh_token', tokenResponse.data?.refresh_token);

                            const updatedFormData = {
                                ...formData,
                                channel: 'W',
                            };

                            const loginResponse = await apiService.logincbayuser(JSON.stringify(payload));
                            if (loginResponse?.data?.message) {
                                localStorage.clear();

                                return toast.error('User not mapped with Diagnostic role');

                            }

                            if (loginResponse?.data) {
                                if (!loginResponse?.data?.user.userRolesList) {
                                    return toast.error('User not mapped with any role');

                                }
                                let found = loginResponse?.data?.user.userRolesList.some(obj => obj.roleCode === 'DIAG');
                                let found1 = loginResponse?.data?.user.userRolesList.some(obj => obj.roleCode === 'DIAGGROUPADMIN');


                                if (found == true || found1 == true) {
                                   role =found == true ? 'DIAG':'DIAGGROUPADMIN'

                                    setSPDetails(loginResponse)
                                    apiService.getApiCMS(loginResponse.data.user.code).then((res) => {
                                        if (res.data && res.data.message === "Data not found") {
                                            setShowConsentView(true);
                                        } else {
                                            if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber || res.data[0].privacyPolicyConsent == 0 || res.data[0].termsAndConditionConsent == 0) {
                                                if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber) {
                                                    setVersionText(true)
                                                }
                                                setShowConsentView(true);
                                            } else {
                                                loginToDashboard(loginResponse);
                                            }
                                        }
                                    }).catch((er) => {
                                        toast.error('Something went wrong', {
                                            position: toast.POSITION.TOP_CENTER,
                                        });
                                    })
                                    // localStorage.setItem('userSession', JSON.stringify(loginResponse.data?.userSession));
                                    // localStorage.setItem('loginObj', JSON.stringify(loginResponse.data))
                                    // localStorage.setItem('userRole', found == true ? 'DIAG' : 'DIAGGROUPADMIN')
                                    // const userInfoResponse = await apiService.userInfo(loginResponse.data?.userSession?.userCode)
                                    // if (userInfoResponse?.data) {
                                    //     localStorage.setItem('location', JSON.stringify(userInfoResponse.data?.[0]))
                                    // }
                                    // navigate(APP_ROUTES.DASHBOARD);
                                }
                                // toast.error('Invalid User', {
                                // position: toast.POSITION.TOP_CENTER,
                                // });
                            } else {
                                console.error('API Error:', loginResponse.statusText);
                            }
                        } else {
                            console.error('API error:', tokenResponse.statusText);
                        }
                    } catch (error) {
                        console.error('API request error:', error);
                    }

                }

            } catch (error) {
                console.error('API request error:', error);
            }

            setLoading(false);

        }
        else {
            if (formData.userCode && formData.passWord) {
                setLoading(true);

                try {
                    const tokenResponse = await apiService.getToken(formData);

                    if (tokenResponse.status === 200 && tokenResponse?.data) {
                        const access_token = tokenResponse.data.access_token;
                        localStorage.clear();
                        localStorage.setItem('access_token', access_token);
                        localStorage.setItem('access_name', formData.userCode.toUpperCase());
                        localStorage.setItem('refresh_token', tokenResponse.data?.refresh_token);

                        const updatedFormData = {
                            ...formData,
                            channel: 'W',
                        };

                        const loginResponse = await apiService.loginApi(JSON.stringify(updatedFormData));

                        if (loginResponse?.data) {
                            let found = loginResponse?.data?.user.userRolesList.some(obj => obj.roleCode === 'DIAG');
                            let found1 = loginResponse?.data?.user.userRolesList.some(obj => obj.roleCode === 'DIAGGROUPADMIN');
                            if (found == false && found1 == false) {
                                return toast.error('Invalid User', {
                                    position: toast.POSITION.TOP_CENTER,
                                });
                            }
                            if (found == true || found1 == true) {
                          role= found == true ? 'DIAG':'DIAGGROUPADMIN'
                                setSPDetails(loginResponse)
                                apiService.getApiCMS(loginResponse.data.user.code).then((res) => {
                                    if (res.data && res.data.message === "Data not found") {
                                        setShowConsentView(true);
                                    } else {
                                        if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber || res.data[0].privacyPolicyConsent == 0 || res.data[0].termsAndConditionConsent == 0) {
                                            if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber) {
                                                setVersionText(true)
                                            }
                                            setShowConsentView(true);
                                        } else {
                                            loginToDashboard(loginResponse);
                                        }
                                    }
                                }).catch((er) => {
                                    toast.error('Something went wrong', {
                                        position: toast.POSITION.TOP_CENTER,
                                    });
                                })
                                // // if(loginResponse?.data?.user?.roleCode == 'DIAGGROUPADMIN' || loginResponse?.data?.user?.roleCode =='DIAG'){
                                // localStorage.setItem('userSession', JSON.stringify(loginResponse.data?.userSession));
                                // localStorage.setItem('loginObj', JSON.stringify(loginResponse.data))
                                // localStorage.setItem('userRole', found == true ? 'DIAG' : 'DIAGGROUPADMIN')
                                // const userInfoResponse = await apiService.userInfo(loginResponse.data?.userSession?.userCode)
                                // if (userInfoResponse?.data) {
                                //     localStorage.setItem('location', JSON.stringify(userInfoResponse.data?.[0]))
                                // }
                                // navigate(APP_ROUTES.DASHBOARD);
                            }

                        } else {
                            console.error('API Error:', loginResponse.statusText);
                            toast.error('Invalid User', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    } else {
                        console.error('API error:', tokenResponse.statusText);
                    }
                } catch (error) {
                    console.error('API request error:', error);
                    toast.error('Invalid User', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

                setLoading(false);
            }
            else if (formData?.userMobileNumber) {
                setLoading(true);
                const payload = {
                    "mobileNumber": formData?.userMobileNumber,
                    "mobileCheck": 2
                };

                try {
                    const otpResponse = await apiService.generateOtp(payload);

                    if (otpResponse.status === 200 && (otpResponse?.data === 1 || otpResponse?.data === 0)) {
                        setShowOtp(true);
                        toast.success('Your OTP has been sent Successfully', {
                            position: toast.POSITION.TOP_CENTER,
                        })
                        setShowSubmitOtp(true);
                    }
                    else if (otpResponse.status === 200 && otpResponse?.data?.details) {
                        toast.error(otpResponse?.data?.details?.[0], {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                    else {
                        toast.error('Please try after sometime', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } catch (error) {
                    console.error('API request error:', error);
                } finally {
                    setLoading(false);
                }

            }

        }
    };
    const loginToDashboard = async (loginResponse) => {
        console.log(role)
        localStorage.setItem('userSession', JSON.stringify(loginResponse.data?.userSession));
        localStorage.setItem('loginObj', JSON.stringify(loginResponse.data))
        localStorage.setItem('userRole',role)
        const userInfoResponse = await apiService.userInfo(loginResponse.data?.userSession?.userCode)
        if (userInfoResponse?.data) {
            localStorage.setItem('location', JSON.stringify(userInfoResponse.data?.[0]))
        }
        navigate(APP_ROUTES.DASHBOARD);
    }

    const onSubmitOTP = async () => {
        setSubmitLoading(true);
        const otpDigits = ['otpDigit1', 'otpDigit2', 'otpDigit3', 'otpDigit4', 'otpDigit5', 'otpDigit6'];

        const allOTPDigitsEntered = otpDigits.every(digit => formData.hasOwnProperty(digit) && formData[digit] !== '');

        if (allOTPDigitsEntered) {
            const fullOTP = otpDigits.map(digit => formData[digit]).join('');
            setFormData({ ...formData, userOTP: fullOTP });
            if (fullOTP) {
                const tokenResponse = await apiService.getToken(formData, fullOTP);

                if (tokenResponse.status === 200 && tokenResponse?.data) {
                    const access_token = tokenResponse.data.access_token;
                    localStorage.clear();
                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('access_name', formData.userCode.toUpperCase());
                    localStorage.setItem('refresh_token', tokenResponse.data?.refresh_token);
                }
                const updatedFormData = {
                    userMobileNumber: formData?.userMobileNumber,
                    userOTP: fullOTP,
                    channel: 'W',
                };

                const loginResponse = await apiService.loginUsingMobile(JSON.stringify(updatedFormData));
                if (loginResponse?.status === 200 && loginResponse?.data?.details?.[0]) {
                    toast.error(loginResponse?.data?.details?.[0], {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    setSubmitLoading(false)
                }
                else if (loginResponse?.data) {
                    setSPDetails(loginResponse)
                    apiService.getApiCMS(loginResponse.data.user.code).then((res) => {
                        if (res.data && res.data.message === "Data not found") {
                            setShowConsentView(true);
                        } else {
                            if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber || res.data[0].privacyPolicyConsent == 0 || res.data[0].termsAndConditionConsent == 0) {
                                if (res.data[0].privacyVersionNumber != consentVersionP.versionNumber || res.data[0].tcVersionNumber != consentVersionT.versionNumber) {
                                    setVersionText(true)
                                }
                                setShowConsentView(true);
                            } else {
                                loginToDashboard(loginResponse);
                            }
                        }
                    }).catch((er) => {
                        toast.error('Something went wrong', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    })
                    // localStorage.setItem('userSession', JSON.stringify(loginResponse.data?.userSession));
                    // localStorage.setItem('loginObj', JSON.stringify(loginResponse.data))
                    // localStorage.setItem('userRole', loginResponse?.data?.user?.roleCode)
                    // const userInfoResponse = await apiService.userInfo(loginResponse.data?.userSession?.userCode)
                    // if (userInfoResponse?.data) {
                    //     localStorage.setItem('location', JSON.stringify(userInfoResponse.data?.[0]))
                    // }
                    // navigate(APP_ROUTES.DASHBOARD);
                } else {
                    console.error('API Error:', loginResponse.statusText);
                }
            }

        }
        else {
            toast.error('Please enter OTP', {
                position: toast.POSITION.TOP_CENTER,
            })
            setSubmitLoading(false)
        }
    }
    const forgetPassword = async () => {
        var mail = /([a-zA-Z0-9]+)([\_\.\-{1}])?([a-zA-Z0-9]+)\@([a-zA-Z0-9]+)([\.])([a-zA-Z\.]+)/g;
        const mo = /^[0-9]\d{9}$/gm

        // if (!this.loginsubmitted) {
        // if(this.loginForm.value)
        if (!forget) {
            return toast.error('Please Enter Email Or Mobile Number');
        }
        var x = forget;
        var regex = /^[0-9]+$/;
        let payload = {};
        if (x.match(regex)) {
            if (!x.match(mo)) {
                return toast.error(' Please enter vaild Mobile Number');
            } else {
                payload = {
                    mobile: x
                }
            }
        } else {
            if (!x.match(mail)) {
                return toast.error(' Please enter valid Email ID  ');
            } else {
                payload = {
                    email: x
                }
            }
            //   }  
        }
        try {
            const res = await apiService.forgetPassword(payload);
            if (!res.message) {
                toast.success("Reset password link has been sent to your registered Mobile Number and Email ID")

            } else if (res && (res.message == 'Validation Failed.')) {
                toast.error('No user is available with this user id');
            } else {
                toast.error('Failed');
            }

        } catch (error) {
            console.error('API request error:', error);
        }
    }
    const handleChangeforget = (e) => {
        setForget(e.target.value)
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleLoginTypeChange = (type) => {
        setLoginType(type);
        if (type === 'mobile') {
            setFormData({
                userCode: '',
                passWord: '',
            });
        }
        else {
            setShowOtp(false);
            setShowSubmitOtp(false);

        }
    };
    const onConsentAgree = () => {
        if (!termsAccept) {
            toast.error('Please agree Terms of use', {
                position: toast.POSITION.TOP_CENTER,
            });
        } else if (!policyAccept) {
            toast.error('Please agree Privacy Policy', {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            const payload = {
                "createdBy": SPdetails.data.user.code,
                "createdDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "modifiedBy": SPdetails.data.user.code,
                "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                "privacyPolicyConsent": 1,
                "sourceChannel": "LB",
                "status": 1,
                "termsAndConditionConsent": 1,
                "type": "S",
                "userId": SPdetails.data.user.code,
                "privacyVersionNumber": consentVersionP.versionNumber,
                "tcVersionNumber": consentVersionT.versionNumber
            }
            apiService.userConsentPost(payload).then((res) => {
                if (res.data === 1) {
                    setShowConsentView(false)
                    loginToDashboard(SPdetails)
                }
            })
        }
    }
    return (
        <div className="flex h-screen">
            <div className="flex-1 hidden lg:flex items-center justify-center">
                <img src={loginImage} alt="Login" className="max-h-full object-cover" />
            </div>

            <div className="flex-1 flex flex-col items-center justify-center bg-white">
                <div className="mb-8 text-center">
                    <p className="font-rubik text-3xl font-medium text-blue-500">
                        Improving Lives Together.
                    </p>
                    <img src={loginDesign} alt='Login Design' className="mt-4 mx-auto" />
                </div>

                <div className="w-[32rem] p-8 bg-white rounded shadow-md">
                    {loginType == 'forget' ? <h2 className="text-xl font-bold mb-8">Forget Password</h2> : <h2 className="text-xl font-bold mb-8">Please Log In </h2>}
                    <form className='flex flex-col items-center' onSubmit={handleFormSubmit}>
                        {loginType === 'email' &&
                            <>
                                <div className="mb-2 w-full text-left">
                                    <label htmlFor="email" className="text-sm font-semibold text-gray-800">
                                        Enter Your Email Id
                                    </label>
                                </div>
                                <div className="mb-4 w-full">
                                    <input
                                        name="userCode"
                                        type="text"
                                        id="userCode"
                                        placeholder='Enter Email'
                                        className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-2 w-full text-left">
                                    <label htmlFor="passWord" className="text-sm font-semibold text-gray-800">
                                        Enter Password
                                    </label>
                                </div>
                                <div className="mb-4 w-full relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="passWord"
                                        name="passWord"
                                        placeholder="Enter your password"
                                        className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40 pr-10" // Added padding for the eye icon
                                        value={formData.passWord}
                                        onChange={handleChange}
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                                {/* <div className="flex justify-end mb-4">
                                    <button
                                        type="button"
                                        className="text-blue-500 hover:underline focus:outline-none"
                                        onClick={() => handleLoginTypeChange('forget')}
                                    >
                                        Forgot Password?
                                    </button>
                                </div> */}
                            </>
                        }
                        {loginType === 'mobile' &&
                            <>
                                <div className="mb-2 w-full text-left">
                                    <label htmlFor="email" className="text-sm font-semibold text-gray-800">
                                        Enter Your Mobile Number
                                    </label>
                                </div>
                                <div className="mb-4 w-full">
                                    <input
                                        name="userMobileNumber"
                                        type="text"
                                        id="userMobileNumber"
                                        placeholder='Enter Mobile Number'
                                        className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                        onChange={handleChange}
                                    />
                                </div>
                                {showOtp && (
                                    <>
                                        <div className="mb-2 w-full text-left">
                                            <label htmlFor="userOTP" className="text-sm font-semibold text-gray-800">
                                                Enter OTP
                                            </label>
                                        </div>
                                        <div className="flex mb-4 w-full">
                                            {inputRefs.map((ref, index) => (
                                                <input
                                                    key={index}
                                                    name='userOTP'
                                                    type="text"
                                                    maxLength="1"
                                                    className="otp-input"
                                                    ref={ref}
                                                    onChange={(e) => handleOTPChange(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                />
                                            ))}
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                type="button"
                                                className="text-blue-500 hover:underline focus:outline-none"
                                                onClick={handleResendOTP}
                                                disabled={resendDisabled}
                                            >
                                                Resend OTP {resendDisabled ? `in ${seconds} seconds` : ''}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </>
                        }
                        {loginType === 'forget' &&
                            <>
                                <div className="mb-2 w-full text-left">
                                    <label htmlFor="email" className="text-sm font-semibold text-gray-800">
                                        Email Or Mobile Number
                                    </label>
                                </div>
                                <div className="mb-4 w-full">
                                    <input
                                        name="userMobileNumber"
                                        type="text"
                                        id="userMobileNumber"
                                        placeholder='Enter Mobile Number'
                                        className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                        onChange={handleChangeforget}
                                    />
                                </div>

                            </>
                        }
                        {
                            !showSubmitOtp && loginType != 'forget' &&
                            <div className="mb-4 w-full ">
                                <button
                                    type="submit"
                                    className="w-full bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out"
                                    disabled={loading}
                                >
                                    {loading ? 'Logging in...' : 'Login'}
                                </button>
                            </div>
                        }
                        {showSubmitOtp && loginType != 'forget' &&
                            <div className="mb-4 w-full">
                                <button
                                    type="button"
                                    onClick={handleSubmitClick}
                                    className="w-full bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out"
                                    disabled={submitLoading}
                                >
                                    {submitLoading ? 'Logging in...' : 'Submit'}
                                </button>
                            </div>
                        }
                        {loginType == 'forget' &&
                            <div className="mb-4 w-full">

                                <button
                                    type="button"
                                    className="text-blue-500 hover:underline focus:outline-none"
                                    onClick={() => handleLoginTypeChange('email')}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={forgetPassword}
                                    className="w-full mt-6 bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out"
                                >
                                    Submit
                                </button>
                            </div>
                        }
                        <div className="flex justify-between w-full">
                            {loginType === 'email' &&
                                <button
                                    type="button"
                                    className={`text-blue-500 focus:outline-none ${loginType === 'mobile' ? 'font-bold' : ''}`}
                                    onClick={() => handleLoginTypeChange('mobile')}
                                >
                                    Login With Mobile No
                                </button>
                            }
                            {loginType === 'mobile' &&
                                <button
                                    type="button"
                                    className={`text-blue-500 focus:outline-none`}
                                    onClick={() => handleLoginTypeChange('email')}
                                >
                                    Login With User ID
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <Modal open={termsView}
                onClose={() => setTermsView(false)} >
                <>
                    <Box
                        sx={{ ...style, width: 1000, height: 600 }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>Terms of use</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setTermsView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            {consentVersionP &&
                                <div className="bg-white">
                                    <iframe src={`${process.env.REACT_APP_IMAGE_URL + consentVersionT.consentFile}`}
                                        height="450px"
                                        width="900px" />
                                </div>
                            }
                        </div>
                    </Box>
                </>
            </Modal>
            <Modal open={privacyView}
                onClose={() => setPrivacyView(false)} >
                <>
                    <Box
                        sx={{ ...style, width: 1000, height: 600 }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>Privacy Policy</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setPrivacyView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            {consentVersionP &&
                                <div className="bg-white">
                                    <iframe src={`${process.env.REACT_APP_IMAGE_URL + consentVersionP.consentFile}`}
                                        height="450px"
                                        width="900px" />
                                </div>
                            }
                        </div>
                    </Box>
                </>
            </Modal>
            <Modal open={showConsentView}
                onClose={() => setShowConsentView(false)} >
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>User Consent</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setShowConsentView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            <div className="bg-white p-5">
                                <p className=''>
                                    {versionTextChange ?
                                        <b>Privacy Policy and Terms and conditions have been changed please check again and give your consent .</b>
                                        :
                                        <b>Please Accept Terms of use and Privacy Policy to continue</b>
                                    }
                                </p>
                                <div className="flex cursor-pointer mt-4">
                                    <div className=" flex space-x-2">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setTermsAccept(e.target.checked)}
                                            class="form-radio"
                                            name="accountType"
                                            value="personal"
                                            checked={termsAccept}
                                        />
                                        <div className="text-xs font-medium  font-montserrat">
                                            <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={() => setTermsView(true)} ><u>Terms and conditions</u></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex cursor-pointer mt-4">

                                    <div className=" flex space-x-2">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setPolicyAccept(e.target.checked)}
                                            class="form-radio"
                                            name="accountType"
                                            value="personal"
                                            checked={policyAccept}
                                        />
                                        <div className="text-xs font-medium  font-montserrat">
                                            <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={(e) => setPrivacyView(true)} ><u>Privacy policy</u></p>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    // type="submit"
                                    onClick={() => onConsentAgree()}
                                    className={`w-48 mt-5 bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out`}
                                >
                                    Agree and continue
                                </button>
                            </div>
                        </div>
                    </div>

                </>
            </Modal>
        </div>
    );
};

export default LoginPage;
