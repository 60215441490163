import React, { useState, useEffect } from 'react';
import CustomDatePicker from '../common/customdatepicker';
import apiService from '../../services/actions';
import { formatDateToMMDDYYYY, formateDateToDDMMYYYYHHMM } from '../common';
import {
    TextField,
    Button,
    Grid,

    Paper,
    FormControl,
    InputLabel,
    Input,
    Select,
    MenuItem,
    CardHeader,
    Collapse,
    Typography,
    Box,
    Pagination,
    CircularProgress,
    Modal,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    IconButton,
} from '@mui/material';
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import avaterBG from "../../assets/images/avaterBG.jpeg"
import { toast } from 'react-toastify';
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const UserProfile = (props) => {
    const navigate = useNavigate();
    const userObj = JSON.parse(localStorage.getItem('loginObj'))
    const [user, setUser] = useState(userObj.user);
    const [otpModal, setotpModal] = useState(false)
    console.log(user)
    const [photoType, setPhotoType] = useState(null);

    const [photo, setPhoto] = useState(null);
    const [stateList, setstateList] = useState([]);
    const [selectedPhoto, setselectedPhoto] = useState("");
    const [photoValue, setPhotoValue] = useState("");


    const [signature, setSignature] = useState(null);

    const [termsView, setTermsView] = useState(false);
    const [privacyView, setPrivacyView] = useState(false);
    const [termsAccept, setTermsAccept] = useState(true);
    const [policyAccept, setPolicyAccept] = useState(true);
    const [SPdetails, setSPDetails] = useState();
    const [consentVersionT, setConsentVersionT] = useState();
    const [consentVersionP, setConsentVersionP] = useState();
    const [showConsentView, setShowConsentView] = useState(false);
    const [versionTextChange, setVersionText] = useState(false);
    const [logoutConfirm, setLogoutConfirm] = useState(false);
    useEffect(() => {
        apiService.getUserConsent().then((result) => {
            const policy = result.data.filter(val => val.constentName === "PrivacyPolicy")
            setConsentVersionP(policy[0])
            const terms = result.data.filter(val => val.constentName === "TermsAndCondition")
            setConsentVersionT(terms[0])
        })
    }, [])
    const handleFileChange = (event, type) => {
        const file = event.target.files[0];

        if (type === 'profile') {
            setPhoto(file);
        } else if (type === 'signature') {
            setSignature(file);
        }
    };

    const handleSubmit = () => {
        // Implement your form submission logic here
    };
    const handleCloseotpModal = () => {
        setotpModal(false)
    }
    const getStatelist = async () => {
        const payload = {

            status: 1,

        };
        try {
            const patientDetailsResponse = await apiService.getStatelist(payload);
            if (patientDetailsResponse.status === 200 && patientDetailsResponse?.data) {
                setstateList(patientDetailsResponse.data)
            } else {
                console.error('API error:', patientDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    }

    const getUserdetails = async () => {
        const payload = {

            code: user.code



        };
        try {
            const res = await apiService.getUserDetails(payload);
            if (res.status === 200 && res?.data) {
                setUser(res.data)
            } else {
                console.error('API error:', res.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    }
    const handleInputChange = (name, value) => {
        setUser(prevUser => ({
            ...prevUser,
            [name]: value,
        }));
        console.log(user)
    }
    useEffect(() => {
        getStatelist()
        getUserdetails()

    }, []);
    const hancelPopup = () => {
        props.onClose()
    }
    const profileValidation = () => {
        // var pwd = /^(?=.\d)(?=.[!@#%^&])(?=.[a-z])(?=.*[A-Z]).{6,12}/;
        var name = /^(?!\d+)(?:[a-zA-Z0-9][a-zA-Z0-9 @&]*)?/gm;
        var address = /^(?!\d+)(?:[a-zA-Z0-9][a-zA-Z0-9 ,@&]*)?/;
        var mail = /([a-zA-Z0-9]+)([\_\.\-{1}])?([a-zA-Z0-9]+)\@([a-zA-Z0-9]+)([\.])([a-zA-Z\.]+)/g;
        var mo = /^[0-9]{10}/

        if (!user.salutation) {
            return toast.error("Please select salutation.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        if (!user.firstName) {
            return toast.error("Please enter First Name.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        else if (!user.firstName.match(name)) {
            return toast.error("Please enter valid  First Name.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        if (!user.lastName) {
            return toast.error("Please enter Last Name.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        else if (!user.lastName.match(name)) {
            return toast.error("Please enter valid Last Name.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        if (!user.dob) {
            return toast.error("Please select date of birth.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        if (!user.roleCode) {
            return toast.error("Please select User Role Code .", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        if (!user.email) {
            return toast.error("Please enter Email ID .", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        else if (!user.email.match(mail)) {
            return toast.error("Please enter valid Email ID .", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        if (!user.gender) {
            return toast.error("Please select gender.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }


        if (!user.mobile) {
            return toast.error("Please enter mobile Number.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        // if (this.premobilno.toString() != this.updateForm.value.mobile)
        //   this.showBasicDialog();
        // else
        //   this.SaveProfile();
        updateUser()
    }
    const updateUser = async () => {
        user.dob = formatDateToMMDDYYYY(user.dob)
        let payload = user
        if (photoValue) {
            payload.photo = photoValue ? photoValue : ''
            payload.photoType = photoType
        }
        payload.dob = formatDateToMMDDYYYY(user.dob)


        // payload.modifiedBy=user.code
        // payload.modifiedDate=user.code




        try {
            let res = await apiService.updateProfile(payload);
            if (res.status === 200 && res?.data) {
                toast.success("Profile Updated Successfully .", {
                    position: toast.POSITION.TOP_CENTER,
                });
                getUserdetails()
                props.onClose()
            } else {
                console.error('API error:', res.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }

    }
    const changeHandler = async (e) => {
        setPhotoType(e.type.split('/')[1])
        let b64File = await encodeBase64File(e);
        setPhotoValue(b64File)
    }
    const encodeBase64File = (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                setselectedPhoto(baseURL)
                // console.log(baseURL.split(',')[1]);
                resolve(baseURL.split(',')[1]);
            };
            console.log(fileInfo);
        });
    }
    const disAgreeConfirm = () => {
        if (!policyAccept || !termsAccept) {
            setLogoutConfirm(true)
        } else {
            setShowConsentView(false)
        }
    }
    const onConsentAgree = () => {
        const payload = {
            "createdBy": user.code,
            "createdDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            "modifiedBy": user.code,
            "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            "privacyPolicyConsent": policyAccept ? 1 : 0,
            "sourceChannel": "LB",
            "status": 1,
            "termsAndConditionConsent": termsAccept ? 1 : 0,
            "type": "S",
            "userId": user.code,
            "privacyVersionNumber": consentVersionP.versionNumber,
            "tcVersionNumber": consentVersionT.versionNumber
        }
        apiService.userConsentPost(payload).then((res) => {
            if (res.data === 1) {
                logOutClick()
            }
        })
    }
    const logOutClick = () => {
        setLogoutConfirm(false)
        setShowConsentView(false)
        localStorage.clear();
        navigate('/')
    }
    return (
        <>

            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px', overflowX: 'auto' }}>
                <h4 style={{ backgroundColor: "rgb(245, 245, 245)", margin: "-16px -15px 16px", padding: "6px", color: "#black" }}>My Profile</h4>
                <div className='flex justify-between'>
                    <label>
                        <input type='file' accept="image/*" onChange={(e) => { changeHandler(e.target.files[0]) }} style={{ display: 'none' }} />

                        <img src={selectedPhoto} style={{ borderRadius: "50%", width: '100px', height: '50px', marginBottom: "10px" }} alt='image' />
                    </label>
                    <p className="cursor-pointer" onClick={() => setShowConsentView(true)}>update consent</p>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>

                        <TextField
                            label="User Id"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.code}
                        // onChange={(e) => handleInputChange('code', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="salutation-label">Salutation</InputLabel>
                            <Select
                                labelId="salutation-label"
                                id="salutation"
                                name="salutation"
                                label="Salutation"
                                value={user.salutation}
                                onChange={(e) => handleInputChange('salutation', e.target.value)}
                                sx={{ minWidth: '100%' }}
                            >
                                <MenuItem value={'Dr.'}>Dr.</MenuItem>
                                <MenuItem value={'Mr.'}>Mr.</MenuItem>
                                <MenuItem value={'Ms.'}>MS.</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.firstName}
                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: "0.5rem" }}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.lastName}
                            onChange={(e) => handleInputChange('lastName', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Proffession"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.roleName ? user.roleName : ""}
                        // onChange={(e) => handleInputChange('roleName', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CustomDatePicker
                            label="DOB"
                        // value={user.dob}
                        // onChange={(date) => setFilters({ ...filters, fromDate: date })}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="salutation-label">Gender</InputLabel>
                            <Select
                                name="gender"
                                label="Gender"
                                value={user.gender}
                                size="small"
                                onChange={(e) => handleInputChange('gender', e.target.value)}
                                sx={{ minWidth: '100%' }}
                            >

                                <MenuItem value={'M'}>Male</MenuItem>
                                <MenuItem value={'F'}>Female</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Mobile Number"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.mobile}
                            onChange={(e) => handleInputChange('mobile', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Qualification"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.qualification}
                            onChange={(e) => handleInputChange('qualification', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Address1"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.address1}
                            onChange={(e) => handleInputChange('address1', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Address2"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.address2}
                            onChange={(e) => handleInputChange('address2', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="City"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.city}
                            onChange={(e) => handleInputChange('city', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="salutation-label">State</InputLabel>
                            <Select
                                name="state"
                                label="State"
                                value={user.state}
                                size="small"
                                onChange={(e) => handleInputChange('state', e.target.value)}
                                sx={{ minWidth: '100%' }}
                            >

                                {stateList.map(option => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.description}
                                    </MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Country"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.country}
                        // onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Pincode"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={user.pinCode}
                            onChange={(e) => handleInputChange('pinCode', e.target.value)}
                        />
                    </Grid>

                </Grid>
                <Grid style={{ direction: "rtl", marginTop: "10px" }}>
                    <Button variant="contained" color="primary"
                        //  onClick={handleAdvancedSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}
                        sx={{ marginLeft: "10px" }}
                        onClick={profileValidation}>
                        Update
                    </Button>
                    <Button variant="contained" color="primary"
                        //  onClick={handleAdvancedSearch} sx={{ borderRadius: '1rem', width: '15rem', marginTop: '1rem', marginLeft: '1rem' }}
                        onClick={hancelPopup}>
                        Cancel
                    </Button>
                </Grid>
            </Paper>
            {/* <Modal open={otpModal}
                onClose={handleCloseotpModal} className='customModal'>
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <OTPForm onClose={handleCloseotpModal} />
                    </div>
                </>
            </Modal> */}
            <Modal open={termsView}
                onClose={() => setTermsView(false)} >
                <>
                    <Box
                        sx={{ ...style, width: 1000, height: 600 }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>Terms of use</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setTermsView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            {consentVersionP &&
                                <div className="bg-white">
                                    <iframe src={`${process.env.REACT_APP_IMAGE_URL + consentVersionT.consentFile}`}
                                        height="450px"
                                        width="900px" />
                                </div>
                            }
                        </div>
                    </Box>
                </>
            </Modal>
            <Modal open={privacyView}
                onClose={() => setPrivacyView(false)} >
                <>
                    <Box
                        sx={{ ...style, width: 1000, height: 600 }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>Privacy Policy</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setPrivacyView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            {consentVersionP &&
                                <div className="bg-white">
                                    <iframe src={`${process.env.REACT_APP_IMAGE_URL + consentVersionP.consentFile}`}
                                        height="450px"
                                        width="900px" />
                                </div>
                            }
                        </div>
                    </Box>
                </>
            </Modal>
            <Modal open={showConsentView}
                onClose={() => setShowConsentView(false)} >
                <>
                    <Box
                        sx={{ ...style, width: 400 }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>User Consent</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setShowConsentView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            <div className="bg-white p-5">
                                <p className=''>
                                    <b>Terms of use and Privacy Policy</b>
                                </p>
                                <div className="flex cursor-pointer mt-4">
                                    <div className=" flex space-x-2">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setTermsAccept(e.target.checked)}
                                            class="form-radio"
                                            name="accountType"
                                            value="personal"
                                            checked={termsAccept}
                                        />
                                        <div className="text-xs font-medium  font-montserrat">
                                            <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={() => setTermsView(true)} ><u>Terms and conditions</u></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex cursor-pointer mt-4">

                                    <div className=" flex space-x-2">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setPolicyAccept(e.target.checked)}
                                            class="form-radio"
                                            name="accountType"
                                            value="personal"
                                            checked={policyAccept}
                                        />
                                        <div className="text-xs font-medium  font-montserrat">
                                            <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={(e) => setPrivacyView(true)} ><u>Privacy policy</u></p>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    // type="submit"
                                    onClick={() => disAgreeConfirm()}
                                    className={`w-48 mt-5 bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out`}
                                >
                                    Update Consent
                                </button>
                            </div>
                        </div>
                    </Box>

                </>
            </Modal>
            <Modal open={logoutConfirm}
                onClose={() => setPrivacyView(false)} >
                <>
                    <Box
                        sx={{ ...style, width: 500 }}>
                        <>
                            <div className="border border-gray-200 my-3 p-5">
                                <p className=''>
                                    <b>Are you sure want to disagree the consent?</b>
                                    <p className="mt-4">if You disagree the consent, the app will logout automatically!</p>
                                </p>
                                <div className="flex justify-end">
                                    <button
                                        onClick={() => setLogoutConfirm(false)}
                                        className={`w-24 mt-5 bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out`}
                                    >
                                        No
                                    </button>
                                    <button
                                        onClick={() => onConsentAgree()}
                                        className={`w-24 ml-2 mt-5 bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out`}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>

                        </>
                    </Box>
                </>
            </Modal>
        </>
    );
};

export default UserProfile
