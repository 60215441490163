import React from "react";
import { APP_ROUTES } from './approutes';
import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "../components/dashboard/index";
import { checkIsLoggedIn } from "../services/actions";
import Login from "../components/login/index";
import ReceivedOrder from "../components/dashboard/receivedOrder";
import Orders from "../components/dashboard/orders";
import Reports from "../components/dashboard/reports";
import Dashboard from "../components/dashboard/dashboard";
import PendingOrders from "../components/dashboard/pendingorders";
import CompletedOrders from "../components/dashboard/completedorders";
import DiognosticsList from "../components/dashboard/diognosticsList";
import CollectionList from "../components/dashboard/colllectionList";

const OurRoutes = (props) => {
    return (
        <Routes>
            <Route exact path={APP_ROUTES.LOGIN} element={<Login />} />
            <Route
                path={APP_ROUTES.DASHBOARD}
                element={
                    <PrivateRoute>
                        <HomePage>
                            <Dashboard />
                        </HomePage>
                    </PrivateRoute>
                }
            />
            <Route
                path={APP_ROUTES.RECEIVEDORDERS}
                element={
                    <PrivateRoute>
                        <HomePage>
                            <ReceivedOrder />
                        </HomePage>
                    </PrivateRoute>
                }
            />
            <Route
                path={APP_ROUTES.ALLORDERS}
                element={
                    <PrivateRoute>
                        <HomePage>
                            <Orders />
                        </HomePage>
                    </PrivateRoute>
                }
            />
            <Route
                path={APP_ROUTES.REPORTS}
                element={
                    <PrivateRoute>
                        <HomePage>
                            <Reports />
                        </HomePage>
                    </PrivateRoute>
                }
            />
            <Route
                path={APP_ROUTES.COMPLETEDORDERS}
                element={
                    <PrivateRoute>
                        <HomePage>
                            <CompletedOrders />
                        </HomePage>
                    </PrivateRoute>
                }
            />
            <Route
                path={APP_ROUTES.LABLIST}
                element={
                    <PrivateRoute>
                          <HomePage>
                          <DiognosticsList />

                          </HomePage>


                    </PrivateRoute>
                }
            />
            <Route
                path={APP_ROUTES.PENDINGORDERS}
                element={
                    <PrivateRoute>
                        <HomePage>
                            <PendingOrders />
                        </HomePage>
                    </PrivateRoute>
                }
            />
                    <Route
                path={APP_ROUTES.COLLECTION}
                element={
                    <PrivateRoute>
                        <HomePage>
                            <CollectionList />
                        </HomePage>
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

const PrivateRoute = ({ children }) => {
    const auth = checkIsLoggedIn();
    return auth ? children : <Navigate to={APP_ROUTES.LOGIN} />;
};

export { OurRoutes };
