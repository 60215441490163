import React, { useCallback, useEffect, useState,use } from 'react';
import {
    Box, TextField, Tab, Tabs, Paper, Button, Typography, Select, MenuItem,
    InputLabel, FormControl, Card, Divider, CardContent, Pagination, Menu,
    CircularProgress, Grid, Modal, CardHeader, IconButton, FormControlLabel,
    Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel
} from '@mui/material';
import CustomDatePicker from '../common/customdatepicker';
import apiService from '../../services/actions';
import cancelIcon from "../../assets/images/cancelIcon.svg";
import successIcon from "../../assets/images/successIcon.svg";
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import { formatDateToMMDDYYYY, formatToCurrentDateTime, formateDateToDDMMYYYYHHMM } from '../common';

function DiagnosticsList() {
    const [sort, setSort] = useState({
        sortBy: 'orderId',
        sortOrder: 'asc',
    });
    const [loading, setLoading] = useState(false);

    const loginObjStr = localStorage.getItem('loginObj');
    const [selectedRow, setSelectedRow] = useState(null);

    const loginObj = JSON.parse(loginObjStr);
    const userCode = loginObj?.userSession?.userCode || null;
    const [diagnosticsList, setDiagnosticsList] = useState([]);
    const [page, setPage] = useState(1);
    const [mode, setMode] = useState('New');
    const location = useLocation();
    const receivedFilter = location.state;

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [addModal, setAddModal] = useState(false);
    const [addObject, setaddObject] = useState({
    });
    const currentDateTime = formatToCurrentDateTime(new Date());
    const [filters, setFilters] = useState(receivedFilter ? {
        status :1,
        businessType:receivedFilter
    }:{
        status: 1,

    });
    useEffect(() => {
        getLabList();
        setaddObject({
            ...addObject,
            ['diagnosticType']: 'B2B2C',
        });

    }, []);
const saveLab=async ()=>{
    if(!addObject.code){
    return toast.error("Please enter Code")}
    if(!addObject.name){
        return toast.error("Please enter Diagnostic Name")}
        if(!addObject.diagnosticType){
            return toast.error("Please select Dionostic Type ")}
            if(addObject.diagnosticType !='LAB' &&!addObject.businessType){
                return toast.error("Please select Bussiness Type")
            }
            let payload = addObject;
            if(mode == 'New'){
            payload.createdDate = currentDateTime;
            payload.type = 'D';

            payload.createdBy = userCode;
            payload.isApproved = 1;
            payload.status = 1
        }
       else {
           
        }
        payload.modifiedBy= userCode;
        payload.modifiedDate= currentDateTime;
        if(mode == 'New'){
          const res = await apiService.saveLab(payload)
            if(!res.message){
handleCloseModal(false)    
getLabList()            
            }
            else{
                toast.error(res.message)
            }
        }
        else{
            const res = await apiService.updateLab(payload)
            if(!res.message){
handleCloseModal(false)    
getLabList()            
            }
            else{
                toast.error(res.message)
            }  
        }
}
    const getLabList = async () => {
        
let payload
if(Object.keys(filters).length != 0){
       payload  = {...filters}
       payload.type = 'D'
       if(payload.fromDate ||  payload.toDate ) {
        payload.fromDate = dayjs(payload.fromDate ? payload.fromDate  : new Date()).format('MM/DD/YYYY')
        payload.toDate = dayjs (payload.toDate ? payload.toDate  : new Date()).format('MM/DD/YYYY')

       }
if(payload.status == 0){
    delete payload.status
}
    }

    else{
        payload = {
            type: 'D',
            status: 1
        };
    }

        try {
            const res = await apiService.getLabs(payload);
            if (res.status === 200 && res?.data) {
                setDiagnosticsList(res?.data);
                // setFilters({})
            } else {
                console.error('API error:', res.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const paginatedData = diagnosticsList.length ? diagnosticsList?.slice((page - 1) * rowsPerPage, page * rowsPerPage) : [];

    const handleOpenModal = () => {
        setAddModal(true);
        setMode('New')
    };
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };

    const handleRowDoubleClick = (row) => {
        console.log(row)
        setMode('View')

        setSelectedRow(row);
        setaddObject(row)
        setAddModal(true);
    };
    const handleCloseModal = () => {
        setAddModal({})
        setAddModal(false);
    };
    const handleInputChange = (field, value) => {
        setaddObject({
            ...addObject,
            [field]: value,
        });
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', marginBottom: '1rem' }}>
                <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: '600' }}>
                    Diagnostic List
                </Typography>
                <div style={{ textAlign: 'right' }}>
    {/* <Button 
        variant="contained" 
        sx={{ borderRadius: "1.875rem", marginRight: "10px" }} 
        onClick={handleOpenModal}
    >
        + New
    </Button> */}
    {/* <Button 
        variant="contained" 
        sx={{ borderRadius: "1.875rem" }} 
        onClick={handleOpenModal}
    >
        + View
    </Button> */}
</div>
            </div>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center" style={{ display: loading ? 'flex' : 'none' }}>
                    <CircularProgress color="secondary" />
                </div>
                <Paper elevation={3} className="p-4 mb-4">
                    <Grid container spacing={2}>
                        {/* Grid items with Tailwind classes */}
                        <Grid item xs={2}>
                        <TextField
                            label="Code"
                            name='code'
                            variant="outlined"
                            fullWidth
                            value={filters.code}
                            size='small'
                            onChange={(e) => setFilters({ ...filters, code: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Diagnostic Name"
                            variant="outlined"
                            name='name'
                            fullWidth
                            value={filters.name}
                            size='small'
                            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                        />
                    </Grid>
                        <Grid item xs={12} md={2}>
                            <CustomDatePicker
                                label="From Date"
                                value={filters.fromDate}
                                onChange={(date) => setFilters({ ...filters, fromDate: date })}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <CustomDatePicker
                                label="To Date"
                                value={filters.toDate}
                                onChange={(date) => setFilters({ ...filters, toDate: date })}
                            />
                        </Grid>
                        <Grid item xs={12} md={2} className="mb-4">
                            <FormControl fullWidth variant="outlined">
                                <InputLabel size="small">Status</InputLabel>
                                <Select
                                    name="status"
                                    label="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    size="small"
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={0}>Include Inactive</MenuItem>
                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                                    <FormControl fullWidth variant="outlined">
                            <InputLabel size='small' >Bussiness Type</InputLabel>
                            <Select
                                label="Diagnostic Type"
                                size='small'
                                name="businessType"

                                value={filters.businessType}
                                onChange={ handleFilterChange}
                            >
                                <MenuItem value={'B2B2C'}>B2B2C</MenuItem>
                                <MenuItem value={'B2B'}>B2B</MenuItem>
                            </Select>
                        </FormControl>
                                    </Grid>
                      <Grid item xs={2} className='flex gap-3'>
                        <Button
                                variant="contained"
                                color="primary"
                                className="mt-4 md:mt-0"
                                onClick={getLabList}
                            >
                                Search
                            </Button>
                    </Grid>  
            
                    </Grid>
                </Paper>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                        
                                    Code
                            </TableCell>
                            <TableCell>
                         
                                    Diagnostic Name
                            </TableCell>
                            <TableCell>
                                    Bussiness Type
                            </TableCell>
                            <TableCell>
                                    Created Date
                            </TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((data, index) => (
                            <TableRow key={data.id}
                            selected={selectedRow?.id === data.id}
                            onClick={() => handleRowClick(data)}
                            onDoubleClick={() => handleRowDoubleClick(data)}
                            sx={{ cursor: 'pointer' }}>
                                <TableCell>{data?.code}</TableCell>
                                <TableCell>{data?.name}</TableCell>
                                <TableCell>{data?.businessType}</TableCell>
                                <TableCell>{data?.createdDate}</TableCell>
                                <TableCell style={{color: data?.status == 1 ?'green' :'red' }}>{data?.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <Pagination
                    count={Math.ceil(diagnosticsList.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
            <Modal open={addModal} onClose={handleCloseModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflowY: 'auto' }}>
                    <Card style={{ width: '80vw', maxWidth: '1200px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 500 }}>
                                    {mode} Diagnostic
                                </Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}
                        />
                        <Divider />
                        <CardContent>
                            <Paper elevation={2} style={{ padding: '16px', overflowX: 'auto' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Code *"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            disabled
                                            value={addObject.code}
                                            onChange={(e) => handleInputChange('code', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Diagnostic Name *"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            size='small'
                                            value={addObject.name}
                                            onChange={(e) => handleInputChange('name', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined">
                            <InputLabel size='small' >Diagnostic Type *</InputLabel>
                            <Select
                                label="Diagnostic Type"
                                size='small'
                                value={addObject.diagnosticType}
                                onChange={(e) => handleInputChange('diagnosticType', e.target.value)}
                            >
                                <MenuItem value={'Collection Center'}>Collection Center</MenuItem>
                                <MenuItem value={'LAB'}>LAB</MenuItem>
                            </Select>
                        </FormControl>
                                    </Grid>
                                   {addObject.diagnosticType != 'LAB' && <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined">
                            <InputLabel size='small' >Bussiness Type *</InputLabel>
                            <Select
                                label="Diagnostic Type"
                                size='small'
                                value={addObject.businessType}
                                onChange={(e) => handleInputChange('businessType', e.target.value)}
                            >
                                <MenuItem value={'B2B2C'}>B2B2C</MenuItem>
                                <MenuItem value={'B2B'}>B2B</MenuItem>
                            </Select>
                        </FormControl>
                                    </Grid>}
                                </Grid>
                            </Paper>
                        </CardContent>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="contained" color="primary" onClick={saveLab}>Save</Button>
                                <Button variant="outlined" color="secondary" style={{ marginLeft: '10px' }} onClick={handleCloseModal}>Cancel</Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
        </div>
    );
}

export default DiagnosticsList;
