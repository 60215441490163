import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const SLAComplianceBarChart = ({ data }) => {
    const chartData = [];

    // Check if the data has daily structure (countDay1totalOrder, countDay1processedOrder, etc.)
    const isDailyData = data[0]?.weekMap?.hasOwnProperty('countDay1totalOrder');

    if (isDailyData) {
        const today = new Date();
        const daysOfWeek = [];

        for (let i = 6; i >= 0; i--) {
            const day = new Date(today);
            day.setDate(today.getDate() - i);
            const dayOfWeek = day.toLocaleDateString('en-US', { weekday: 'short' });
            daysOfWeek.push(dayOfWeek);
        }

        for (let i = 0; i < daysOfWeek.length; i++) {
            const totalOrderKey = `countDay${i + 1}totalOrder`;
            const processedOrderKey = `countDay${i + 1}processedOrder`;
            const yetToProcessedKey = `countDay${i + 1}yetToProcessed`;

            chartData.push({
                name: daysOfWeek[i],
                totalOrder: data[0]?.weekMap?.[totalOrderKey] || 0,
                processedOrder: data[0]?.weekMap?.[processedOrderKey] || 0,
                yetToProcessed: data[0]?.weekMap?.[yetToProcessedKey] || 0,
            });
        }
    } else {
        // Check if the data has weekly structure (countWeek1PendingOrder, countWeek1CompletedOrder, etc.)
        const isWeeklyData = data?.[0]?.weekMap?.hasOwnProperty('countweek1totalOrder');

        if (isWeeklyData) {
            const weeks = [
                'week1', 'week2', 'week3', 'week4', 'week5'
            ];

            for (let i = 0; i < weeks.length; i++) {
                chartData.push({
                    name: weeks[i],
                    processedOrders: data[0]?.weekMap?.[`count${weeks[i]}processedOrder`] || 0,
                    yetToProcessed: data[0]?.weekMap?.[`count${weeks[i]}yetToProcessed`] || 0,
                    totalOrders: data[0]?.weekMap?.[`count${weeks[i]}totalOrder`] || 0,
                });
            }
        }
        else {
            // Assuming it's yearly data with month-wise details
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            for (let i = 0; i < months.length; i++) {
                const month = months[i].toLowerCase();
                const totalOrderKey = `${month}totalOrder`;
                const processedOrderKey = `${month}processedOrder`;
                const yetToProcessedKey = `${month}yetToProcessed`;

                chartData.push({
                    name: months[i],
                    totalOrder: data[0]?.yearMap?.[totalOrderKey] || 0,
                    processedOrder: data[0]?.yearMap?.[processedOrderKey] || 0,
                    yetToProcessed: data[0]?.yearMap?.[yetToProcessedKey] || 0,
                });
            }
        }
    }

    return (
        <BarChart
            width={700}
            height={300}
            data={chartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalOrder" fill="#8884d8" name="Total Orders" />
            <Bar dataKey="processedOrder" fill="#82ca9d" name="Processed Orders" />
            <Bar dataKey="yetToProcessed" fill="#ffc658" name="Yet to Process" />
        </BarChart>
    );
};

export default SLAComplianceBarChart;
