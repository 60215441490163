import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const LabOrdersLineChart = ({ data }) => {
    const chartData = [];
    const today = new Date();
    const days = [];

    // Generate the last 7 days, starting from today
    for (let i = 6; i >= 0; i--) {
        const day = new Date(today);
        day.setDate(today.getDate() - i);
        days.push(day.toLocaleDateString('en-US', { weekday: 'short' }));
    }

    // Check if the data has daily structure (countDay1PendingOrder, countDay1CompletedOrder, etc.)
    const isDailyData = data[0]?.hasOwnProperty('countDay1PendingOrder');

    if (isDailyData) {
        for (let i = 0; i < days.length; i++) {
            chartData.push({
                name: days[i],
                pendingOrders: (data[0] && data[0][`countDay${i + 1}PendingOrder`]) || 0,
                completedOrders: (data[0] && data[0][`countDay${i + 1}CompletedOrder`]) || 0,
            });
        }
    } else {
        // Check if the data has weekly structure (countWeek1PendingOrder, countWeek1CompletedOrder, etc.)
        const isWeeklyData = data?.[0]?.weekMap?.hasOwnProperty('countWeek1PendingOrder');

        if (isWeeklyData) {
            const weeks = [
                'Week1', 'Week2', 'Week3', 'Week4', 'Week5'
            ];

            for (let i = 0; i < weeks.length; i++) {
                chartData.push({
                    name: weeks[i],
                    pendingOrders: (data[0] && data[0]?.weekMap?.[`count${weeks[i]}PendingOrder`]) || 0,
                    completedOrders: (data[0] && data[0]?.weekMap?.[`count${weeks[i]}CompletedOrder`]) || 0,
                });
            }
        } else {
            // Assuming the data structure for monthly/yearly data is consistent
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            for (let i = 0; i < months.length; i++) {
                chartData.push({
                    name: months[i],
                    pendingOrders: (data[0] && data[0]?.yearMap?.[`${months[i].toLowerCase()}PendingOrder`]) || 0,
                    completedOrders: (data[0] && data[0]?.yearMap?.[`${months[i].toLowerCase()}CompletedOrder`]) || 0,
                });
            }
        }
    }

    return (
        <LineChart
            width={700}
            height={300}
            data={chartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="linear" dataKey="pendingOrders" stroke="#E04573" name="Pending Orders" strokeWidth={3} />
            <Line type="linear" dataKey="completedOrders" stroke="#4570E0" name="Completed Orders" strokeWidth={3} />
        </LineChart>
    );
};

export default LabOrdersLineChart;
