import React, { useEffect, useState } from 'react';
import loginBgImage from '../../assets/images/login.jpeg';
import apiService from '../../services/actions';
import { useNavigate } from "react-router";
import { APP_ROUTES } from '../../routes/approutes';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';

const Login = () => {
    const navigate = useNavigate();
    const backgroundStyle = {
        backgroundImage: `url(${loginBgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1,
    };

    const formContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '20px',
        marginRight: '120px',
        margin: '120px'
    };

    const [formData, setFormData] = useState
        ({
            userCode: '',
            passWord: '',
        });
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.userCode && formData.passWord) {
            setLoading(true);

            try {
                const tokenResponse = await apiService.getToken(formData);

                if (tokenResponse.status === 200 && tokenResponse?.data) {
                    const access_token = tokenResponse.data.access_token;
                    localStorage.clear();
                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('access_name', formData.userCode.toUpperCase());
                    localStorage.setItem('refresh_token', tokenResponse.data?.refresh_token);

                    const updatedFormData = {
                        ...formData,
                        channel: 'W',
                    };

                    const loginResponse = await apiService.loginApi(JSON.stringify(updatedFormData));

                    if (loginResponse?.data) {
                        localStorage.setItem('userSession', JSON.stringify(loginResponse.data?.userSession));
                        localStorage.setItem('loginObj', JSON.stringify(loginResponse.data))
                        localStorage.setItem('userRole', loginResponse?.data?.user?.roleCode)
                        const userInfoResponse = await apiService.userInfo(loginResponse.data?.userSession?.userCode)
                        if (userInfoResponse?.data) {
                            localStorage.setItem('location', JSON.stringify(userInfoResponse.data?.[0]))
                        }
                        navigate(APP_ROUTES.DASHBOARD);
                    } else {
                        console.error('API Error:', loginResponse.statusText);
                    }
                } else {
                    console.error('API error:', tokenResponse.statusText);
                }
            } catch (error) {
                console.error('API request error:', error);
            }

            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div style={backgroundStyle}>
            <div style={formContainerStyle}>

                <div className='bg-white flex-col flex items-center justify-center' >
                    <div>
                        <Typography variant="h5" className='bg-white pt-5 pb-5 flex flex-row' >
                            <img
                                src='/cureBay.png'
                                alt={'dashboardLogo'}
                                style={{ height: '3rem', marginLeft: '1rem', marginRight: '0.5rem' }}
                            />
                            <text style={{ fontWeight: 'bold' }}>CureBay</text>
                        </Typography>
                    </div>

                    <div class="border-t-2 border-black-500">
                        <div className='items-center ' style={{ fontWeight: 'bolder', fontSize: '20px', margin: "25px" }}>
                            LOGIN
                        </div>
                        <div className='flex'>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-2 p-5">
                                    <label
                                        htmlFor="email"
                                        className="flex text-sm font-semibold text-gray-800"
                                    >
                                        Email
                                    </label>
                                    <input
                                        name="userCode"
                                        type="text"
                                        id="userCode"
                                        placeholder='Enter Email'
                                        className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                        style={{ width: "300px" }}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-2 p-5">
                                    <input
                                        type="passWord"
                                        id="passWord"
                                        name="passWord"
                                        className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                        placeholder="Enter your passWord"
                                        style={{ width: "300px" }}
                                        value={formData.passWord}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* Submit button */}
                                <div className="mb-2 p-5">
                                    <button
                                        type="submit"
                                        className="w-full bg-blue-500 hover:bg-blue-600 text-white text-lg font-medium py-2 rounded-lg transition duration-300 ease-in-out"
                                        disabled={loading}
                                    >
                                        {loading ? 'Logging in...' : 'Login'}
                                    </button>
                                </div>

                                {/* <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                    <div class="relative w-5 h-5 bg-purple-400 rounded-full flex justify-center items-center text-white text-center p-3 shadow-xl" style={{backgroundColor:"#475569"}}>
                        Or
                    </div>
                    </div> */}

                                {/* <div className="mb-10 p-5">
                        <label
                            for="email"
                            className="flex block text-sm font-semibold text-gray-800"
                        >
                            Mobile number
                        </label>
                        <input
                            type="text"
                            placeholder='Enter Number'
                            className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        style={{width:"300px"}}
                        />
                    </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Login;
