import React, { useEffect, useState } from 'react';
import {
    Box, TableContainer, Table, TableHead, TableRow, TableCell,
    TableBody, Paper, CircularProgress, Pagination, Typography,
    TextField, Grid, Button
} from '@mui/material';
import apiService from '../../services/actions';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import CustomDatePicker from '../common/customdatepicker';

function CollectionList() {
    const [loading, setLoading] = useState(false);
    const [tableData, settTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const location = useLocation();
    const receivedFilter = location.state;
    const [filters, setFilters] = useState({
        fromDate:dayjs().subtract(7, 'day'),
        toDate:  dayjs(),
    });

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        setLoading(true);
        let payload = {
            ...filters,

            fromDate: filters.fromDate ? dayjs(filters.fromDate).format('MM/DD/YYYY') : dayjs(new Date()).format('MM/DD/YYYY'),
            toDate: filters.toDate ? dayjs(filters.toDate).format('MM/DD/YYYY') : dayjs(new Date()).format('MM/DD/YYYY'),
        };


        try {
            const res = await apiService.getCollectionList(payload);
            if (res.status === 200 && res?.data) {
                settTableData(res.data);
            } else {
                console.error('API error:', res.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleDateChange = (name, date) => {
        setFilters({ ...filters, [name]: date });
    };

    const paginatedData = tableData.length ? tableData.slice((page - 1) * rowsPerPage, page * rowsPerPage) : [];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2rem' }}>
            <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: '600', marginBottom: '1rem' }}>
                SM Collection Count
            </Typography>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center" style={{ display: loading ? 'flex' : 'none' }}>
                <CircularProgress color="secondary" />
            </div>
            <Paper elevation={3} className="p-4 mb-4">
                <Grid container spacing={2}>
                <Grid item xs={2}>
                        <TextField
                            label="Collection Centre"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={filters.ccName}
                            onChange={(e) => setFilters({ ...filters, ccName: e.target.value  })}
                        />
                    </Grid>
                <Grid item xs={2}>
                        <TextField
                            label="FirstName"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={filters.firstName}
                            onChange={(e) => setFilters({ ...filters, firstName: e.target.value  })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="LastName"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={filters.lastName}
                            onChange={(e) => setFilters({ ...filters, lastName: e.target.value  })}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
            
                             <CustomDatePicker
                            label="From Date"
                            value={filters.fromDate}
                            onChange={(date) => setFilters({ ...filters, fromDate: date  })}

                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                                <CustomDatePicker
                            label="To Date"
                            value={filters.toDate}
                                                        onChange={(date) => setFilters({ ...filters, toDate: date  })}/>

                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={getList}
                            sx={{ height: '100%' }}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Code</TableCell> */}
                            <TableCell sx={{ fontWeight: 'bold' }}>SM UserName</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Collection Centre</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((data) => (
                            <TableRow key={data.id}>
                                <TableCell>{data.smUserName}</TableCell>
                                <TableCell>{data.collectionCenterName}</TableCell>
                                <TableCell>{data.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <Pagination
                    count={Math.ceil(tableData.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </div>
    );
}

export default CollectionList;
