import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';

export default function CustomDateTimePicker({ label, onChange, defaultDateTime }) {
  const [selectedDateTime, setSelectedDateTime] = React.useState(defaultDateTime || null);

  const handleDateTimeChange = (dateTime) => {
    setSelectedDateTime(dateTime);
    onChange(dateTime);
  };

  const combinedSlotProps = {
    actionBar: {
      actions: ['clear','accept'],
    },
    textField: {
      size: 'small',
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <MobileDateTimePicker
          slotProps={combinedSlotProps}
          style={{ paddingTop: '0px' }}
          label={label || "Date & Time"}
          onChange={handleDateTimeChange}
          value={selectedDateTime}
          minDate={dayjs()}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
