import React, { useEffect, useState, useCallback } from 'react';
import {
  Paper,
  Tab,
  Tabs,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Divider,
  Box,
  Pagination,
  CircularProgress,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import apiService from '../../services/actions';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import PatientSearch from '../common/patientSearch';
import { formatToCurrentDateTime, formatToLocalDateTime } from '../common';
const Mail = (props) => {
  const userObj = JSON.parse(localStorage.getItem('loginObj'))
  const [advancedSearchPage, setAdvancedSearchPage] = useState(1);
  const [user, setUser] = useState(userObj.user);
  const [patientSearch, setpatientSearch] = useState(false);
  const [selectedrow, setSelectedrow] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const currentDateTime = formatToCurrentDateTime(new Date());
  const patientDetailsPerPage = 5;
  const patientDetailsPerPage2 = 5;

  const [newModal, setnewModal] = useState(false);
  const [content, setnotificationContent] = useState({
    content: '',
    subject: '',
    fromName: '',
    patientName: '',
    email: '',
  });
  const [activeTab, setActiveTab] = useState(1);

  const [sentMaillist, setsentMaillist] = useState([]);
  const [maillist, setMaillist] = useState([]);
  const handleClose = () => {
    setnewModal(false)
  }
  const handleClosepatientSearch = (e) => {
    console.log(e)
    setSelectedPatient(e)
    setpatientSearch(false)
  }
  const openSearchFilter = () => {
    setpatientSearch(true)
  }
  const addMail = async () => {
    let payload
      = {
      "createdBy": user.email,
      "createdDate": currentDateTime,
      "fromDate": currentDateTime,
      "modifiedBy": user.email,
      "modifiedDate": currentDateTime,
      "status": 1,
      "subject": content.subject,
      "content": content.content,
      "toCode": selectedPatient.code,
      "toName": selectedPatient.name,
      "patientName": selectedPatient.name,
      "fromCode": user.email,
      "fromName": content.fromName,
      "trigeredUsertype": "P"
    }

    try {
      const res = await apiService.postMails(payload);
      if (res.status === 200 && res?.data) {
        console.error(res.status);
        setnewModal(false)
        getsentmail()

      } else {
        console.error('API error:', res.statusText);
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  }
  const getsentMail = useCallback((advancedSearchPage) => {
    const startIndex = (advancedSearchPage - 1) * patientDetailsPerPage;
    const endIndex = startIndex + patientDetailsPerPage;
    const dataForPage = sentMaillist.slice(startIndex, endIndex);
    return dataForPage;
  }, [patientDetailsPerPage, sentMaillist])
  const getinboxMail = useCallback((advancedSearchPage) => {
    const startIndex = (advancedSearchPage - 1) * patientDetailsPerPage2;
    const endIndex = startIndex + patientDetailsPerPage2;
    const dataForPage = maillist.slice(startIndex, endIndex);
    return dataForPage;
  }, [patientDetailsPerPage2, maillist])
  const handleTabClick = (e) => {
    setActiveTab(e)
    setSelectedrow(null)
  }
  const getsentmail = async () => {
    const payload = {
      "fromCode": user.email
    }



    try {
      const res = await apiService.getsentMail(payload);
      if (res.status === 200 && res?.data) {
        setsentMaillist(res?.data)
      } else {
        console.error('API error:', res.statusText);
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  }
  const getmaillist = async (payload) => {
    if (!payload) {
      payload = {
        status: 1,
        toCode: user.email
      }
    }


    try {
      const res = await apiService.getInboxMail(payload);
      if (res.status === 200 && res?.data) {
        setMaillist(res?.data)
      } else {
        console.error('API error:', res.statusText);
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  }
  const cancelprops = () => {
    props.onClose()
  }
  const handleInputChange = (field, value) => {
    setnotificationContent({
      ...content,
      [field]: value,
    });
  }
  const handleRowDoubleClick = (rowObject) => {
    // Do something with the entire object of the clicked row
    setSelectedrow(rowObject)
    // You can perform additional actions or set state based on the clicked row
  };
  const cancelNotification = () => {
    setnewModal(false)
  }
  const handleChange = () => {
    setnewModal(true)
  };
  const handlePatientPageChange = (event, value) => {
    setAdvancedSearchPage(value);
  }
  useEffect(() => {
    getsentmail();
    getmaillist();
    if (user.roleCode == 'CHE') {
      const payload = {
        cheCenterCode: userObj.hospitalId,
        cheBrachCode: userObj.locationId,
        status: 1
      }
      this.getmaillist(payload);
    }
  }, [activeTab]);
  return (
    <>
      <div className="bg-white p-4 rounded-lg shadow-md w-90 md:w-3/4 lg:w-3/4 mx-auto mt-8">
        <h2 className="text-center mb-4">Mail</h2>
        <div className="flex justify-end mb-4">
          <IconButton color="secondary">
            <MailIcon onClick={() => handleChange()} />
          </IconButton>
        </div>
        <Tabs
          value={activeTab}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab label="Inbox() " onClick={() => handleTabClick(2)}
          />
          <Tab label="Sent()" onClick={() => handleTabClick(1)}
          />
        </Tabs>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div>
            {activeTab === 1 && <div>
              {/* <Table data={data} />   */}
              <TableContainer component={Paper} className="print-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>

                      <TableCell>Subject</TableCell>
                      <TableCell>Content</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getsentMail(advancedSearchPage).map((notifi) => (
                      <TableRow onDoubleClick={() => handleRowDoubleClick(notifi)}>
                        <TableCell>{formatToLocalDateTime(notifi.createdDate)}</TableCell>
                        <TableCell>{notifi.subject}</TableCell>

                        <TableCell>{notifi.content}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>}
            {activeTab === 2 &&
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow  >
                      <TableCell>Date</TableCell>

                      <TableCell>Subject</TableCell>
                      <TableCell>Content</TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getinboxMail(advancedSearchPage).map((notifi) => (
                      <TableRow onDoubleClick={() => handleRowDoubleClick(notifi)}
                      >
                        <TableCell>{formatToLocalDateTime(notifi.createdDate)}</TableCell>
                        <TableCell>{notifi.subject}</TableCell>

                        <TableCell>{notifi.content}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </div>
          {selectedrow && <div className='border rounded-lg'>
            <div className=' p-3'>
              <div className='flex items-center justify-between mb-2'>
                <div className='flex items-center'>
                  <label>To :</label>
                  <div className=' font-light pl-3'>{selectedrow?.toName}</div>
                </div>
                <div className='flex items-center'>
                  <label>Sent Date :</label>
                  <div className=' font-light pl-3'>{formatToLocalDateTime(selectedrow?.createdDate)}</div>
                </div>
              </div>
              <div className='flex items-center mb-2'>
                <label>Subject :</label>
                <div className=' font-light pl-3'>{selectedrow?.subject}</div>
              </div>
              <div className='flex items-center mb-2'>
                <label>Messages : </label>
                <div className=' font-light pl-3'>{selectedrow?.content}</div>
              </div>
            </div>
          </div>}
        </div>
        {maillist?.length > 0 && (
          <Box className="flex justify-center mt-4">
            <Pagination
              count={Math.ceil(maillist.length / patientDetailsPerPage2)}
              page={advancedSearchPage}
              color="secondary"
              onChange={handlePatientPageChange}
            />
          </Box>
        )}
        {sentMaillist?.length > 0 && (
          <Box className="flex justify-center mt-4">
            <Pagination
              count={Math.ceil(sentMaillist.length / patientDetailsPerPage)}
              page={advancedSearchPage}
              color="secondary"
              onChange={handlePatientPageChange}
            />
          </Box>
        )}
        <Grid className="flex justify-end">
          <Button variant="contained" color="primary" onClick={cancelprops}>
            Cancel
          </Button>
        </Grid>
      </div>
      <Modal open={newModal}
        onClose={handleClose} className='customModal'>
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Paper elevation={3} style={{ width: '80%', maxWidth: '600px', padding: '16px', marginBottom: '16px', overflowX: 'auto', maxHeight: '80vh' }}>
              <h4 style={{ backgroundColor: "rgb(245, 245, 245)", margin: "-16px -15px 16px", padding: "6px", color: "#black" }}>New Mail</h4>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>

                  <TextField
                    label="From"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={user.firstName}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    label="To"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={selectedPatient?.name}

                    onChange={(e) => handleInputChange('patientName', e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={openSearchFilter} >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }} />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    label="Subject"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={content.subject}

                    onChange={(e) => handleInputChange('subject', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4} // Set the number of rows you want to display
                    size='small'
                    value={content.content}
                    onChange={(e) => handleInputChange('content', e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid style={{ direction: "rtl", marginTop: "10px" }}>
                <Button variant="contained" color="primary"
                  //  onClick={handleAdvancedSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}
                  sx={{ marginLeft: "10px" }}
                  onClick={addMail}
                >
                  Sent
                </Button>

                <Button variant="contained" color="primary"
                  onClick={cancelNotification}
                >
                  Cancel
                </Button>
              </Grid>
            </Paper>            </div>
        </>
      </Modal>
      <Modal open={patientSearch}
        onClose={handleClosepatientSearch} >
        <PatientSearch onClose={handleClosepatientSearch} />
      </Modal>
    </>

  );
};

export default Mail;
