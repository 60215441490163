import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { OurRoutes } from './routes';
import theme from './theme';
import Login from './components/login';
import LoginPage from './components/login/login';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/*" element={<OurRoutes />} />
        </Routes>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;
