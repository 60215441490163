export const APP_ROUTES = {

    LOGIN: '/',
    DASHBOARD: '/dashboard',
    RECEIVEDORDERS: '/receivedOrders',
    ALLORDERS: '/allOrders',
    REPORTS: '/reports',
    COMPLETEDORDERS: '/completedOrders',
    PENDINGORDERS: '/pendingOrders',
   LABLIST: '/lablist',
   COLLECTION: '/collection'



}